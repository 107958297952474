import React, { useContext,useEffect } from 'react';
import { WillContext } from '../../context/WillContext';
import WillMaker from '../../apis/WillMaker';
const BeneficiaryAllocationsInfo = () => {

const {userPSQL,allocations,setAllocations, beneficiaries,assets} = useContext(WillContext);

useEffect ( () => {
  const fetchData = async () => {
    try {
      const response = await WillMaker.get(`/${userPSQL.id}/allocations`);
      console.log(`ReviewPage.jsx --> deferredCall --> after call to WillMaker.get(/${userPSQL.id}/allocations)`);
      console.log(response);
      console.log(response.data.allocations);
      console.log("ReviewPage.jsx --> about to setAllocations");
      setAllocations(() => {return response.data.allocations});
      console.log("ReviewPage.jsx --> deferredCall --> about to onRefresh");

    }
    catch (err) {
      console.log(`ReviewPage.jsx --> useEffect 1 --> in err call to WillMaker.get(/${userPSQL.id}/allocations`);
      console.log(err);
    }
  }
  
  fetchData();

}, []);

  return (
    <div>
        <h2 className='font-bold text-2xl'>Beneficiary Allocations</h2>
      {beneficiaries &&
        beneficiaries.map((beneficiary) => {
          // loop through each beneficiary
          // list them only if they have at least one asset > 0%
          const beneficiary_allocations = allocations &&
            allocations
              .filter(
                (allocation) =>
                  allocation.beneficiary_id === beneficiary.id &&
                  allocation.allocated_percentage > 0
              )
              .sort((a, b) => a.asset_id - b.asset_id);

          const hasAllocations = beneficiary_allocations.length > 0;

          return (
            <div key={beneficiary.id}>
              <table className='mt-5' style={{ borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid black', padding: '8px' ,width:'400px' }}>
                      Beneficiary
                    </th>
                    <th style={{ border: '1px solid black', padding: '8px' ,width:'400px' }}>
                      Asset Type
                    </th>
                    <th style={{ border: '1px solid black', padding: '8px' ,width:'400px' }}>
                      Allocated Percentage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {hasAllocations ? (
                    <>
                      <tr>
                        <td
                          rowSpan={beneficiary_allocations.length + 1}
                          style={{ border: '1px solid black', padding: '8px' }}
                        >
                          <div className='flex flex-col gap-5'>
                           <strong>{`${beneficiary.first_name} ${beneficiary.last_name} `} <span className='text-white p-2 rounded-md bg-purple-600'>{`${beneficiary.relationship}`}</span></strong>
                           <strong><span></span>{beneficiary.alternate_beneficiaries ? `Alternate Beneficiary: ${beneficiary.alternate_beneficiaries}` : "No alternate beneficiary specified"}</strong>
                           </div>
                        </td>
                      </tr>
                      {beneficiary_allocations.map((beneficiary_allocation) => (
                        <tr key={beneficiary_allocation.id}>
                          <td style={{ border: '1px solid black', padding: '8px' }}>
                            {assets &&
                              assets.filter(
                                (asset) => asset.id === beneficiary_allocation.asset_id
                              )[0].type}
                          </td>
                          <td style={{ border: '1px solid black', padding: '8px' }}>
                            {beneficiary_allocation.allocated_percentage}%
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td style={{ border: '1px solid black', padding: '8px' }}>
                        <strong>{`${beneficiary.first_name} ${beneficiary.last_name} `} <span className='text-white p-2 rounded-md bg-purple-600'>{`${beneficiary.relationship}`}</span></strong>
                      </td>
                      <td
                        colSpan="2"
                        style={{
                          border: '1px solid black',
                          padding: '8px',
                          fontStyle: 'italic',
                          color: 'red',
                        }}
                      >
                        You have added the beneficiary but haven't allocated any assets.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          );
        })}
    </div>
  )
}

export default BeneficiaryAllocationsInfo
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Navigation from '../components/Navigation';
import { Helmet } from 'react-helmet-async';
import { toast, Toaster } from "react-hot-toast";
export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ucd9se9', 'template_gqhmolk', form.current, 'wPoobS60l_BAfgEFg')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  const handleSubmit =()=>{
    toast.success("We have recieved your message",{
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        }});
  }

  return (

    <div>
    <Toaster
        toastOptions={{ duration: 4000 }}
        position="top-center"
        reverseOrder={false} />
    <Helmet>
    <title>Contact | wowwill</title>
    <meta name='description' content='Contact Us'/>
    <link rel="canonical" href="https://wowwill.in/contact" />
    
 
    </Helmet>
    <Navigation/>
    <h1 className='text-3xl font-bold text-center my-5'>Contact us</h1>
    <div className='shadow-lg bg-gray-50 h-full my-auto max-w-[900px] sm:mx-auto mx-3 px-5 py-8 rounded-3xl'>
    <form className='flex flex-col gap-5 items-center' ref={form} onSubmit={sendEmail}>
      <label className='font-bold' placeholder='your name'>Name</label>
      <input required className='sm:w-[50%] w-full border-2 rounded-md h-[35px]' type="text" name="user_name" />
      <label className='font-bold' placeholder='your email'>Email</label>
      <input required className='sm:w-[50%] w-full  border-2 rounded-md h-[35px]' type="email" name="user_email" />
      <label className='font-bold' placeholder='Your message'>Message</label>
      <textarea required className='sm:w-[50%] w-full  border-2 rounded-md' name="message" />
      <input className='bg-[#5a17ee] px-12 py-3 rounded-md text-white font-bold hover:opacity-80' type="submit" value="Send" onClick={handleSubmit}/>
    </form>
    </div>
    
    </div>
  );
};
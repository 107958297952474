import React, { useContext } from 'react';
import { WillContext } from '../../context/WillContext';

const ExecutorInfo = () => {
  const {executor} = useContext(WillContext);
  console.log(executor,"ExecutorInfo");
  return (
    <div>
        <h2 className='font-bold text-2xl mt-4'>Executor Info</h2>
        {executor.map((executor) => (
  <p key={executor.id} className=' font-medium text-xl mt-5'>
   <span className='underline'>{executor.title} {executor.first_name} {executor.last_name}</span>  is executor of will and {executor.alternate_executor} is alternate executor of your will. 
  </p>
))}
        
    </div>
  )
}

export default ExecutorInfo
import React from 'react'
import {HiOutlineArrowNarrowRight} from 'react-icons/hi'
import ButtonForward from './Button/ButtonForward'
const UserWelcomePage = ({onSubmit, onGoBack}) => {

   const handleSubmit = ()=>{
    onSubmit(1);
   }
   
    return (
        <div>
  <div className="flex-1 w-full">
    <div className="py-2">
      <h1 className="text-4xl mt-5 mb-10 font-bold text-center">Welcome To WowWill!</h1>
      <div className="border border-gray-200 text-center rounded-md w-full mx-auto md:w-4/5 px-7">
        <h2 className="text-2xl text-gray-500 mt-10">Here's what to expect:</h2>
        <div className="flex flex-col md:flex-row justify-center gap-4 my-10">
          <div>
            <span className="rounded-full py-[0.4rem] px-3 bg-gray-800 text-white font-bold">1</span>
            <p className="text-xl text-gray-600 mt-10">Tell us a bit about you so we may customize your will to your situation.</p>
          </div>
          <div>
            <span className="rounded-full py-[0.4rem] px-3 bg-gray-800 text-white font-bold">2</span>
            <p className="text-xl text-gray-600 mt-10">Based on your answers, we’ll show you the right prices and options for you.</p>
          </div>
          <div>
            <span className="rounded-full py-[0.4rem] px-3 bg-gray-800 text-white font-bold">3</span>
            <p className="text-xl text-gray-600 mt-10">Print, date, sign and have two witnesses sign. Will Well Done!!</p>
          </div>
        </div>
        <ButtonForward Text="I want to create my will" handleNext={handleSubmit} />
      </div>
    </div>
  </div>
</div>

    )
}

export default UserWelcomePage
import React, { useEffect, useRef, useState } from 'react';
import {graphcms, CREATE_COMMENT} from '../../Graphql/Mutations'

const CommentsForm = ({ slug }) => {

  const [error, setError] = useState(false);
  const [localStorage, setLocalStorage] = useState(null);
  const [showSuccessMessage, setSetshowSuccessMessage] = useState(false);

  const commentEl = useRef();
  const nameEl = useRef();
  const emailEl = useRef();
  const storeDataEl = useRef();

  useEffect(() => {
    nameEl.current.value = window.localStorage.getItem('name');
    emailEl.current.value = window.localStorage.getItem('email');
  }, [])
  

  const handleCommentSubmission = async () => {
    console.log("CommentsForm.jsx --> Entered");

    setError(false);

    const { value: comment } = commentEl.current;
    const { value: name } = nameEl.current;
    const { value: email } = emailEl.current;
    const { checked: storeData } = storeDataEl.current;

    console.log(`CommentsForm.jsx --> comment: ${comment} name: ${name} email: ${email}`);


    if (!comment || !name || !email) {
      setError(true);
      return;
    }

    const commentObj = { name, email, comment, slug };

    if (storeData) {
      window.localStorage.setItem('name', name);
      window.localStorage.setItem('email', email);
    } else {
      window.localStorage.removeItem('name', name)
      window.localStorage.removeItem('email', email) 
    }

    console.log("CommentsForm.jsx --> Before graphcms request");
    console.log(commentObj);

    const res = await graphcms.request(CREATE_COMMENT, commentObj);
    console.log("CommentsForm.jsx --> After graphcms request");

    console.log(res)

    setSetshowSuccessMessage(true);
    setTimeout(() => {
      setSetshowSuccessMessage(false);
    }, 3000);

  }

  return (
    <div className="shadow-lg rounded-lg p-8 mb-8">
      <h3 className="text-xl mb-8 font-semibold border-b pb-4">Leave a Comment</h3>
      <div className='grid grid-cols-1 gap-4 mb-4'>
        <textarea ref={commentEl}
          className="p-4 outline-none w-full rounded-lg bg-gray-100 focus:ring-2 focus:ring-gray-200 text-gray-700"
          placeholder="Comment"
          name="comment"
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
        <input type="text"
          ref={nameEl}
          className="py-2 px-4 outline-none w-full rounded-lg bg-gray-100 focus:ring-2 focus:ring-gray-200 text-gray-700"
          placeholder="Name"
          name="name"
        />
        <input type="text"
          ref={emailEl}
          className="py-2 px-4 outline-none w-full rounded-lg bg-gray-100 focus:ring-2 focus:ring-gray-200 text-gray-700"
          placeholder="Email"
          name="email"
        />
        <div className="grid grid-cols-1 gap-4 mb-4">
          <div>
            <input type="checkbox"
              ref={storeDataEl}
              className=""
              id="storeData"
              name="storeData"
              value="true"
            />
            <label className="ml-2 text-gray-500 cursor-pointer" htmlFor="storeData" >
              Save my name and email for the next time I comment
            </label>
          </div>
        </div>
      </div>
      {error && <p className="text-xs-500 text-red-500">All fields are required.</p>}
      <div className='mt-8'>
        <button type="button"
          onClick={handleCommentSubmission}
          className="transition duration-500 ease-in hover:bg-indigo-400 inline-block bg-pink-400 text-lg rounded-full px-8 py-3 cursor-pointer">
          Post Comment
        </button>
        {showSuccessMessage && <span className="text-xl float-right font-semibold mt-3 text-green-400" >Comment will be posted upon approval. Comment submited for review</span>}
      </div>
    </div>
  )
}

export default CommentsForm
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const defaultHeaders = {
    'ngrok-skip-browser-warning': 'true' // You can set any value here
  };

const WillMaker = axios.create({
    baseURL: `${baseURL}/api/v1/users`,
    headers: defaultHeaders    
});

const WillAssets = axios.create({
    baseURL: `${baseURL}/api/v1/assets`,
    headers: defaultHeaders
});

const BetaSignup = axios.create({
    baseURL: `${baseURL}/api/v1`,
    headers: defaultHeaders
});

const FeedbackApi = axios.create({
    baseURL: `${baseURL}/api/v1`,
    headers: defaultHeaders
});

export const ApiV1 = axios.create({
    baseURL: `${baseURL}/api/v1`,
    headers: defaultHeaders
});

export {WillAssets, BetaSignup, FeedbackApi };
export default WillMaker;


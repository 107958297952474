import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const FaqsContainer = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const faqData = [
      {
        question: 'What is a will?',
        answer: 'A will is a legal document that outlines your wishes regarding the distribution of your assets and the care of any dependents after your death.',
      },
      {
        question: 'Do I need a lawyer to make a will?',
        answer: 'While it\'s not required, it\'s highly recommended to consult with a lawyer to ensure your will is legally valid and accurately reflects your intentions.',
      },
      {
        question: 'What happens if I don\'t have a will?',
        answer: 'If you die without a will (intestate), the distribution of your assets and the guardianship of your minor children will be determined by state laws, which may not align with your preferences.',
      },
      {
        question: 'Can I update my will?',
        answer: 'Yes, you can update your will at any time. It\'s a good practice to review and revise your will periodically, especially after major life events such as marriage, divorce, or the birth of a child.',
      },
    ];
  
    const handleQuestionClick = (index) => {
      if (index === activeIndex) {
        setActiveIndex(null);
      } else {
        setActiveIndex(index);
      }
    };
  return (
    <div>
        <div className="py-20 bg-gray-100 flex justify-center items-center">
      <div className="max-w-[900px] w-full p-6">
        <h1 style={{ fontFamily: 'Noto sans' }} className="text-4xl font-semibold mb-8 text-center">Frequently Asked Questions</h1>
        <div className="space-y-4">
          {faqData.map((faq, index) => (
            <div
              key={index}
              className={`${
                activeIndex === index ? 'bg-blue-100' : 'bg-gray-100'
              } p-4 transition overflow-hidden`}
            >
              <div
                className="flex justify-between items-center w-full cursor-pointer font-medium text-2xl"
                onClick={() => handleQuestionClick(index)}
              >
                {faq.question}
                {activeIndex === index ? <FaMinus /> : <FaPlus />}
              </div>
              <div
                className={`mt-2 text-gray-700 ${
                  activeIndex === index ? 'max-h-96 opacity-100 transition-all duration-500' : 'max-h-0 opacity-0'
                } overflow-hidden`}
              >
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  )
}

export default FaqsContainer
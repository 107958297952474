import React, { useContext, useEffect, useState } from "react";
import WillMaker from "../apis/WillMaker";
import { WillContext } from "../context/WillContext";
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import ButtonForward from "./Button/ButtonForward";
import ButtonBack from "./Button/ButtonBack";
import { toast, Toaster } from "react-hot-toast";

const options = [
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
  { value: "Mstr.", label: "Mstr." },
  { value: "Dr.", label: "Dr." },
];




const Beneficiaries = ({ onSubmit, onGoBack }) => {
  const { userPSQL, beneficiaries, setBeneficiaries } = useContext(WillContext);
  const [runOnce, setRunOnce] = useState(false);
  const [disableAddBeneficiary, setDisableAddBeneficiary] = useState(true);
  const [alternateBeneficiaryVisible, setAlternateBeneficiaryVisible] = useState(false);
  const [inputValues, setInputValues] = useState({
    title: "",
    firstName: "",
    lastName: "",
    relation: "",
    alternateBeneficiary: "",
  });



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await WillMaker.get(`/${userPSQL.id}/beneficiaries`);
        console.log("fetching beneficiaries");
        console.log(response);
        // eslint-disable-next-line array-callback-return
        response.data.beneficiaries.map((beneficiary) => {
          console.log(beneficiary);
        });
        setBeneficiaries(response.data.beneficiaries);

      }
      catch (err) {
        console.log(err);
      }
    }
    fetchData();

  }, [runOnce, setBeneficiaries]);

  const handleAddBeneficiary = async () => {
    setBeneficiaries([...beneficiaries, inputValues]); // add the current input values to the beneficiaries array
    setInputValues({ title: "", firstName: "", lastName: "", relation: "", alternateBeneficiary: "" }); // reset the input values

    // Wirte new beneficiary back to database

    try {

      await WillMaker.post(`/${userPSQL.id}/beneficiaries/`, {
        "id": userPSQL.id,
        "title": inputValues.title, // "Mr."
        "first_name": inputValues.firstName,
        "last_name": inputValues.lastName,
        "relationship": inputValues.relation,
        "alternate_beneficiaries": inputValues.alternateBeneficiary
      });
      toast.success("Beneficiary added successfully!");

      const updatedBeneficiaries = await WillMaker.get(`/${userPSQL.id}/beneficiaries`);
      console.log("fetching beneficiaries");
      console.log(updatedBeneficiaries);
      setBeneficiaries(updatedBeneficiaries.data.beneficiaries);


    }
    catch (err) {
      console.log('err in handleAddBeneficiary');
      console.log(err);
    }



  }


  const handleNext = () => {
    onSubmit("beneficiary", beneficiaries);

  }


  const togglePersonalDetails = () => {
    setAlternateBeneficiaryVisible(!alternateBeneficiaryVisible);
  };



  const handleInputChange = (e) => {
    console.log("Beneficiaries --> handleInputChange: ", e.target.name, e.target.value);
    for (let key in inputValues) {
      console.log("Beneficiaries --> handleInputChange before: ", key, inputValues[key]);
    };
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });


  };

  useEffect(() => {
    // Check if any of the required fields are empty
    const requiredFields = ["title", "firstName", "lastName", "relation"];
    const isAnyFieldEmpty = requiredFields.some((field) => inputValues[field] === "");

    // Enable the Add Beneficiary button if none of the required fields are empty
    setDisableAddBeneficiary(isAnyFieldEmpty);

  }, [inputValues]);



  const handleGoBack = () => {
    onGoBack(0);
  };

  const handleSelect = async (beneficiary) => {
    try {
      console.log(beneficiary.user_id, "beneficiary.user_id");
      console.log(beneficiary.id, "beneficiary.id");
  
      const response = await WillMaker.put(`/${userPSQL.id}/beneficiaries/${beneficiary.id}`);

      setBeneficiaries((prevBeneficiaries) =>
            prevBeneficiaries.filter((b) => b.id !== beneficiary.id));
  
      // if (response.ok) {
      //   const data = await response.json();
      //   if (data.status === 'success') {
      //     // Update the state to remove the archived beneficiary
      //     setBeneficiaries((prevBeneficiaries) =>
      //       prevBeneficiaries.filter((b) => b.id !== beneficiary.id)
      //     );
      //   }
      // } else {
      //   console.error('Failed to archive beneficiary');
      // }
      console.log(response);
    } catch (error) {
      console.error('Error in handleSelect', error);
    }
  };
  

  return (
    <div className="text-center mt-10 px-10">
      <Toaster
        toastOptions={{ duration: 4000 }}
        position="top-center"
        reverseOrder={false} />
      <h1 className="font-bold text-4xl">Beneficiary Details</h1>
      {console.log("in beneficiary component")}
      <p className="text-gray-500 mt-4">
        Please tell us your beneficiaries who will receive your assets{" "}
      </p>
      <p className="text-xs text-gray-500 mt-4">(Please enter one beneficiary at a time)</p>
      <p className="text-xs text-gray-500 mt-4">(Relax! You can specify how to split the assets between beneficiaries later)</p>
      <div className="flex flex-col mt-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="title">
              Title
            </label>
            <select className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="title"
              name="title"
              value={inputValues.title}
              onChange={handleInputChange}>
              <option value="{value}">Select Title</option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="first_name">
              First Name <span className="text-red-500">*</span>
            </label>
            <input
              name="firstName"
              required
              value={inputValues.firstName}
              onChange={handleInputChange}
              placeholder="First name"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>


          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="last_name">
              Last Name <span className="text-red-500">*</span>
            </label>
            <input
              required
              name="lastName"
              value={inputValues.lastName}
              onChange={handleInputChange}
              placeholder="Surname"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="relation">
              Relation<span className="text-red-500">*</span>
            </label>
            <select
              name="relation"
              value={inputValues.relation}
              onChange={handleInputChange}
              className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value="">Relation</option>
              <option value="Son">Son</option>
              <option value="Daughter">Daughter</option>
              <option value="Father">Father</option>
              <option value="Mother">Mother</option>
              <option value="Brother">Brother</option>
              <option value="Sister">Sister</option>
              <option value="Wife">Wife</option>
              <option value="Husband">Husband</option>
              <option value="Friend">Friend</option>
              <option value="Other">Other</option>
            </select>
          </div>




        </div>

        <div>
          <h2 className="flex text-gray-800 text-lg font-bold mb-4 cursor-pointer items-center gap-2 mt-5" onClick={togglePersonalDetails}>
            Additional Information {alternateBeneficiaryVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </h2>
          {alternateBeneficiaryVisible && (
            <div className="text-left">
              <p>In the event that the above beneficiary were to predecease me, I hereby bequeath the following assets to the individual(s) mentioned below</p>
              <label className="block text-gray-700 text-sm font-bold my-2"
                htmlFor="alternate_beneficiary">
                Alternate Beneficiary
              </label>

              <input

                name="alternateBeneficiary"
                value={inputValues.alternateBeneficiary}
                onChange={handleInputChange}
                placeholder="Name of Alternate Beneficiary"
                className="shadow appearance-none border rounded md:w-1/2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          )}
        </div>
        <div title={disableAddBeneficiary ? "Please enter all information about the beneficiary" : ""}>
          <button
            onClick={handleAddBeneficiary}
            disabled={disableAddBeneficiary ? true : false}
            className={`block mx-auto mt-5 \
      bg-emerald-500 \
      ${disableAddBeneficiary ? 'opacity-40 cursor-not-allowed' : 'hover:opacity-80'} \
      font-semibold text-white text-xl px-24 py-3 rounded \
      `}
          >


            Add Beneficiary
          </button>
        </div>
      </div>
      <div className="mt-8 text-left">
        <h2 className="font-bold text-2xl">List of Beneficiaries</h2>
        {beneficiaries && beneficiaries.length === 0 ? (
          <p className="text-gray-500 text-xl">No beneficiaries added yet</p>
        ) : (
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-6">
            {beneficiaries &&
              beneficiaries.map((beneficiary) => (
                <li key={beneficiary.id} className="bg-gray-800 text-white rounded-lg shadow-lg">
                  <div className="p-6 flex items-center gap-4">
                    <div className=" bg-blue-500 rounded-full flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 4a4 4 0 100 8 4 4 0 000-8zM12 14a7 7 0 00-7 7v1h14v-1a7 7 0 00-7-7z"
                        />
                      </svg>
                    </div>
                    <div>
                      <h3 className="text-lg font-bold">
                        {beneficiary.title} {beneficiary.first_name} {beneficiary.last_name}
                      </h3>
                      <p className="text-sm text-gray-300">{beneficiary.relationship}</p>

                      <p className="text-sm text-gray-300">
                        {beneficiary.alternate_beneficiaries ? `Alternate Beneficiary: ${beneficiary.alternate_beneficiaries}` : "No alternate beneficiary specified"}

                      </p>

                    </div>
                  </div>
                  <div className="bg-gray-700 py-2 px-4 flex justify-end">
                    <button 
                    onClick={() => handleSelect(beneficiary)}
                    className="text-red-500 font-semibold">Remove</button>
                  </div>
                </li>
              ))}
          </ul>

        )}
      </div>
      <div className="mt-8">
        <ButtonForward Text="Next" handleNext={handleNext} />
        <ButtonBack Text="Back" handleGoBack={handleGoBack} />
      </div>
    </div>

  );
};

export default Beneficiaries;

import React, { useContext } from 'react';
import { WillContext } from '../../context/WillContext';

const UsersInfo = () => {
    const {userPSQL} = useContext(WillContext);
  return (
    <div>
        <h2 className='font-bold text-2xl'>Personal Information</h2>
      <div className="grid my-8 grid-cols-1 text-left md:grid-cols-2 gap-4 p-6 w-3/4 rounded shadow-xl mx-auto">
      
      
      
      <p><strong>Full Name: </strong>{userPSQL.title} {userPSQL.first_name} {userPSQL.middle_name} {userPSQL.last_name}</p>
     
      <p><strong>Age:</strong> {userPSQL.age}</p>
      <p><strong>Email:</strong> {userPSQL.email}</p>
      <p><strong>Phone Number:</strong> {userPSQL.phone_number}</p>
      {userPSQL.address_line_1 && <p><strong>Address Line 1:</strong> {userPSQL.address_line_1}</p>}
      {userPSQL.address_line_2 && <p><strong>Address Line 2:</strong> {userPSQL.address_line_2}</p>}
      {userPSQL.city && <p><strong>City:</strong> {userPSQL.city}</p>}
      {userPSQL.state && <p><strong>City:</strong> {userPSQL.state}</p>}
      {userPSQL.country && <p><strong>City:</strong> {userPSQL.country}</p>}
      {userPSQL.pincode && <p><strong>City:</strong> {userPSQL.pincode}</p>}
      
      
      
    </div>
    </div>
  )
}

export default UsersInfo
import React from 'react'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
const AboutUs = () => {
    return (
        <div>
            <Navigation />
            <h1 style={{ fontFamily: 'Noto sans' }} className='font-bold text-4xl text-center my-8'>About Us</h1>
            <div className='max-w-[900px] mx-auto px-4 my-8'>
                <h2 style={{ fontFamily: 'Noto sans' }} className='text-2xl font-semibold'>Who We Are</h2>
                <p className='text-xl'>Welcome to WowWill, your trusted partner for creating wills online in a simple and convenient way. 
                We understand that planning for the future is important, and we're here to make the process easy and accessible 
                to everyone.</p>
                <h2 style={{ fontFamily: 'Noto sans' }} className='text-2xl font-semibold mt-6'>Our Mission</h2>
                <p className='text-xl'>Our mission is to empower individuals to take control of their legacy by providing a user-friendly platform 
                to create legally sound wills. We're committed to offering a seamless online experience that ensures your wishes 
                are documented and your loved ones are taken care of.</p>
                <h2 style={{ fontFamily: 'Noto sans' }} className='text-2xl font-semibold mt-6'>What We Do</h2>
                <p className='text-xl'>At WowWill, we specialize in enabling you to create your will from the comfort of your home. Our online 
                platform guides you through the process step-by-step, ensuring you cover all essential aspects of your estate planning. We simplify complex legal jargon and provide a straightforward way to safeguard your assets and provide for your family's future.</p>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs
import React from 'react'
import Navigation from '../../components/Navigation'
import Article1Img from '../../Assets/Article1.jpg'
import { Helmet } from 'react-helmet-async'
import Footer from '../../components/Footer'
const Article1 = () => {
    return (
        <div>
        <Helmet>
        <title>Blog | wowwill</title>
        <meta name='description' content="Validating wills with witnesses: Learn eligibility criteria, role, and signing requirements. Ensure your legacy's authenticity. "/>
        <link rel="canonical" href="https://wowwill.in/blog/role-of-a-witness" />
        </Helmet>
            <Navigation />
            <div className='container mt-10 max-w-[1000px]'>


                <article>
                    <h1 className='text-center font-black text-5xl'>What is a Witness? What is role of a Witness?</h1>
                    <img src={Article1Img} alt='Crop businessman giving contract to woman to sign' width={'1000px'} height={'667px'}  className='my-10'/>
                    <h2 className='mt-10 text-4xl font-black'>Introduction</h2>
                    <p className='mt-2 text-xl'>

                        Creating a legally valid will is crucial to ensure that your assets and possessions are distributed according
                        to your wishes after your passing. While the process of drafting a will may seem straightforward, there are
                        specific legal requirements that must be met to ensure its validity. One crucial aspect is the presence of
                        witnesses. In this article, we will explore the requirements and eligibility criteria for witnesses in a
                        will.

                    </p>
                    <h2 className='mt-10 text-4xl font-black'>Role of Witnesses in a Will</h2>
                    <p className='mt-2 text-xl'>
                        Witnesses play a vital role in validating the authenticity of a will. Their presence serves to verify that the
                        testator (the person making the will) signed the document willingly and was of sound mind at the time of signing.
                        Witnesses provide an impartial and independent testimony to the court, if required, and can confirm that the will
                        indeed reflects the testator's intentions.

                    </p>
                    <h2 className='mt-10 text-4xl font-black'>Number of Witnesses</h2>
                    <p className='mt-2 text-xl'>
                        The number of witnesses required for a will varies depending on the jurisdiction. Typically, two or more
                        witnesses are needed, but it's essential to check the specific legal requirements in your country or state.
                    </p>
                    <h2 className='mt-10 text-4xl font-black'>Eligibility Criteria for Witnesses</h2>
                    <p className='mt-2 text-xl'>
                        To ensure the will's validity, the witnesses must meet certain eligibility criteria. These criteria generally include the following:

                        a. Age: In most jurisdictions, witnesses must be of legal age, which is typically 18 years old or older.

                        b. Competence: Witnesses must be mentally competent and capable of understanding the nature of the document they are witnessing.

                        c. Disqualification: Individuals who stand to inherit anything from the will are usually disqualified from being witnesses.
                        This ensures that witnesses have no personal interest in the contents of the will.
                    </p>
                    <h2 className='mt-10 text-4xl font-black'>Impartiality and Unbiased Witnesses</h2>
                    <p className='mt-2 text-xl'>
                    Selecting witnesses who are neutral parties is essential. Ideally, witnesses should be individuals who are not beneficiaries of the
                     will and have no potential conflicts of interest. Close family members, such as spouses, children, or other beneficiaries, may be deemed ineligible as witnesses in many jurisdictions.
                    </p>
                    <h2 className='mt-10 text-4xl font-black'>Witnessing the Signing</h2>
                    <p className='mt-2 text-xl'>
                    For a will to be valid, the witnesses must be present during the signing of the document by the testator. They must observe the testator sign the will and also sign it themselves as witnesses. The witnesses' signatures affirm that they were present during the signing and can vouch for its authenticity.

                    </p>
                    <h2 className='mt-10 text-4xl font-black'>Signing in Each Other's Presence</h2>
                    <p className='mt-2 text-xl'>
                    In some jurisdictions, witnesses are required to sign the will in each other's presence. This means that all witnesses must be physically present at the same time and watch each other sign the document. This requirement further bolsters the legitimacy of the will.
                    </p>
                    <h2 className='mt-10 text-4xl font-black'>Conclusion</h2>
                    <p className='mt-2 text-xl'>The presence of witnesses is a crucial aspect of ensuring the validity of a will. Their role in attesting to the testator's capacity and willingness to create the will adds credibility to the document. When creating your will, it is essential to adhere to the specific legal requirements for witnesses in your jurisdiction to avoid potential challenges to its validity. Seek legal advice if you have any doubts or questions regarding the process, as ensuring your will's legality is an essential step in safeguarding your legacy and protecting your loved ones.</p>
                    
                </article>

            </div>
            <Footer/>
        </div>
    )
}

export default Article1
import React, { useEffect, useState } from 'react'
import { graphcms, QUERY_POSTS_RECENT, QUERY_POSTS_SIMILAR } from '../../Graphql/Queries'
import moment from 'moment';
import { NavLink } from 'react-router-dom';

const PostWidget = ({categories, slug}) => {

  console.log("In PostWidget.jsx");
  const [relatedPosts, setRelatedPosts] = useState([]);
  
  useEffect (() => {
    if (slug) {
      console.log("PostWidget.jsx: before graphcms query 1")
      graphcms.request(QUERY_POSTS_SIMILAR(slug, categories))
      .then(res => setRelatedPosts(res.posts));
      console.log("PostWidget.jsx: after graphcms query 1")

    } else {
      console.log("PostWidget.jsx: before graphcms query 2")

    graphcms.request(QUERY_POSTS_RECENT)
    .then(res => setRelatedPosts(res.posts));
    console.log("PostWidget.jsx: after graphcms query 2")

    }
  }, [slug]);

  console.log(relatedPosts);

  return (
    
    <div className='bg-white shadow-lg rounded-lg p-8 mb-8'>
      <h3 className='text-xl mb-8 font-semibold border-b pb-4'>{slug ? 'Related Posts' : 'Recent Posts'}</h3>
      {relatedPosts.map((post) => (
        <div key={post.id} className='flex items-center w-full mb-4'>
          <div className="w-16 flex-none">
            <img 
              alt={post.title}
              className="align-middle rounded-full"
              height="60px"
              width="60px"
              src={post.featuredImage.url}
              />
          </div>
          <div className="flex-grow ml-4">
            <p className="text-gray-500 font-xs">
              {moment(post.createdAt).format("DD MMM, YYYY")}
              </p>
              <NavLink key={post.id} to={`/posts/article/${post.slug}`} className="text-md">
                {post.title}
              </NavLink>

          </div>

        </div>
      ))}
      </div>
  )
}

export default PostWidget
import React from 'react'

const ButtonBack = ({handleGoBack}) => {
  return (
    <div>
        <button
          onClick={handleGoBack}
          className="hover:opacity-80 text-center mx-auto font-semibold text-[#5a17ee] text-xl mb-10"
        >
          Back
        </button>
    </div>
  )
}

export default ButtonBack
import React, { useState} from 'react';

import { toast, Toaster } from "react-hot-toast";
import Navigation from './Navigation';
import axios from "axios";
import Footer from './Footer';
const Feedback = () => {

    // const { userPSQL} = useContext(WillContext);

    const [inputValues, setInputValues] = useState({
        pageName: "",
        comments: "",
      });

      const handleSubmitFeedback =  async() => {
        // Your code to submit the feedback data to the backend API goes here.
        try {
            
            await axios.post("api/v1/feedback",{
              
              "pageName": inputValues.pageName,
              "comments": inputValues.comments,
              
            });
            
        }
          catch (err) {
            console.log('err in Feedback fetching');
            console.log(err);
          }
          toast.success('Feedback received. We appreciate your valuable input!');
          
      };  
      
      
      const handleCommentsChange = (e) => {
        // Limit the comments to 500 characters
        const value = e.target.value.slice(0, 500);
        setInputValues({ ...inputValues, comments: value });
      };
    
      const handleChange = (e) => {
        setInputValues({ ...inputValues, [e.target.name]: e.target.value });
      };
    
      const charCount = 500 - inputValues.comments.length;      
  return (
    <div>
    <Navigation/>
    <Toaster toastOptions={{ duration: 4000 }} />
        <div className='w-full h-full flex justify-center items-center mt-5'>
            <div className='bg-white p-6 rounded-lg shadow-md md:w-1/2 w-full'>
              <h2 className='text-xl font-bold mb-4'>Feedback</h2>
              <label className='block mb-4'>
                Select Page
                <select
                  className='block w-full border border-gray-300 rounded p-2'
                  name='pageName'
                  value={inputValues.pageName}
                  onChange={handleChange}
                >
                  <option value='User Profile'>User Profile</option>
                  <option value='Asset Selection'>Asset Selection</option>
                  <option value='Add Beneficiary'>Add Beneficiary</option>
                  <option value='Add Executor'>Add Executor</option>
                  <option value='Asset Allocation'>Asset Allocation</option>
                  <option value='Review'>Review</option>
                  <option value='Pdf Generation'>Pdf Generation</option>
                  <option value='Other'>Other</option>
                  
                </select>
              </label>

              <label className='block mb-4'>
                 Your comments
                <textarea
                  className='block w-full border border-gray-300 rounded p-2'
                  name='comments'
                  value={inputValues.comments}
                  onChange={handleCommentsChange}
                  rows={4}
                  maxLength={500}
                  placeholder='Enter your feedback...'
                />
                <div className='text-gray-500 text-right mt-2'>
                  {charCount}/500 characters
                </div>
              </label>

              <div className='flex justify-end'>
               
                <button
                  className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded'
                  onClick={handleSubmitFeedback}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <Footer/>
    </div>
  )
}

export default Feedback
import React from "react";
import { CiMenuFries } from "react-icons/ci";
import { BsCheckCircleFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
export default function Navigation() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <nav className="relative bg-white flex flex-wrap items-center justify-between border-b-[1px]">
        <div className="container mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <NavLink to="/"
              className=" text-4xl flex items-center gap-3 font-bold leading-relaxed mr-4 py-2 whitespace-nowrap  text-black"

            >
              <BsCheckCircleFill className="text-[#5a17ee]" />
              wowwill <span
                className=" text-red-500 px-2 py-1 rounded-full ml-1 text-xs font-semibold relative border border-red-500
     top-[-5px] left-0"
                style={{ transform: 'translate(-30%, 30%)' }}
              >
                Beta
              </span>
            </NavLink>

            <button
              className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <CiMenuFries />
            </button>
          </div>
          <div
            className={"lg:flex items-center" + (navbarOpen ? " flex" : " hidden")}
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">

            <li className="nav-item">
                <NavLink
                  to="/contact"
                  className="px-3 py-2 ml-4 flex items-center text-base font-bold leading-snug text-black hover:text-[#3772FF]"
                >
                  Contact Us
                </NavLink>
              </li>

              <li className="nav-item">
                {/* <div onMouseLeave={() => setOpen(false)} className="relative">
                  <button
                    onMouseOver={() => setOpen(true)}
                    className="flex items-center  p-2 bg-white rounded-md"
                  >
                    <span className="mx-10 text-base font-bold leading-snug">Blog</span>
                  </button>
                  <ul
                    onMouseOver={() => setOpen(true)}
                    className={`absolute right-0 w-40 pb-2 z-10 text-xl rounded-lg shadow-xl ${open ? "block" : "hidden"
                      }`}
                  >
                    <NavLink to='/blog/role-of-a-witness' className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                      Role of Witness in a Will
                    </NavLink> */}
                    <NavLink to='/posts' 
                  className="px-3 py-2 ml-4 flex items-center text-base font-bold leading-snug text-black hover:text-[#3772FF]"
                  > Blog
                    </NavLink>
                    {/* <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                      Dropdown List 2
                    </li>
                    <li className="flex w-full items-center px-3 py-2 text-sm hover:bg-gray-100">
                      Dropdown List 3
                    </li>
                  </ul>
                </div> */}
              </li>

              <li className="nav-item">
                <NavLink
                  to="/faqs"
                  className="px-3 py-2 flex items-center text-base font-bold leading-snug text-black hover:text-[#3772FF]"
                >
                  FAQs
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/feedback"
                  className="px-3 py-2 ml-4 flex items-center text-base font-bold leading-snug text-black hover:text-[#3772FF]"
                >
                  Feedback
                </NavLink>
              </li>
            </ul>
          </div>


        </div>

      </nav>
    </>
  );
}
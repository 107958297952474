import React from 'react';
import UsersInfo from './UsersInfo';
import BeneficiaryAllocationsInfo from './BeneficiaryAllocationsInfo';
import ExecutorInfo from './ExecutorInfo';
import ButtonForward from '../Button/ButtonForward';
import ButtonBack from '../Button/ButtonBack';

const ReviewPage = ({ onSubmit, onGoBack }) => {

  const handleNext = () => {
    onSubmit(1);
  };

  const handleGoBack = () => {
    onGoBack(0);
  };



  return (
    <div className='text-center'>
      <h1 className='font-bold text-4xl my-5'>Review</h1>
      <UsersInfo/>
      <BeneficiaryAllocationsInfo/>
      <ExecutorInfo/>
      <ButtonForward Text="Next" handleNext={handleNext} />
      <ButtonBack handleGoBack={handleGoBack} />
    </div>
  );
};

export default ReviewPage;

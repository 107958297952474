import React, { useContext, useEffect, useState } from 'react'
import { WillContext } from '../context/WillContext';
import WillMaker from '../apis/WillMaker';
import AllocationBy from './AllocationBy';
import ButtonForward from './Button/ButtonForward';
import ButtonBack from './Button/ButtonBack';

const AssetAllocation = ({ onSubmit, onGoBack, answers, onRefresh }) => {


  const { userAssets, setUserAssets,beneficiaries, allocations,userPSQL } = useContext(WillContext);
  // const [userAssetIds, setUserAssetIds] = useState(null);
  const [userAllocations, setUserAllocations] = useState(null);
  const [allAssetsGreen, setAllAssetsGreen] = useState(false);
  const [assetStatus, setAssetStatus] = useState({});
  const [isPopupVisible, setPopupVisible] = useState(true);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  useEffect(() => {
    // Initialize asset status
    console.log("AssetAllocation.jsx --> UseEffect 1");
    if (userAssets) {

      const initialAssetStatus = {};
      userAssets.forEach(userAsset => {
        initialAssetStatus[userAsset.id] = true;
      });
      setAssetStatus(() => {return initialAssetStatus;});
    }
  }, [userAssets]);

  const handleAssetStatusChange = (assetId, isGreen) => {
    setAssetStatus(prevState => ({
      ...prevState,
      [assetId]: isGreen,
    }));
  };

  // console.log(Object.values(assetStatus).every(status => status),"Asset Status");

  const handleNext = () => {
    // Check if all assets are green
    const allAssetsGreen = Object.values(assetStatus).every(status => status);
    if (allAssetsGreen) {
      console.log("AssetAllocation.jsx --> handleNext --> All assets are green");      
      onSubmit(2);
    }
    else {
      console.log("AssetAllocation.jsx --> handleNext --> All assets are NOT green");
    }
  };

  const handleGoBack = () => {
    onGoBack(0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await WillMaker.get(`/${userPSQL.id}/user_assets`);
        console.log(`AssetAllocation.jsx --> useEffect 2 --> after call to Willmaker.get /${userPSQL.id}/user_assets`);
        console.log("AssetAllocation.jsx --> useEffect 2 --> Below is the order of asset_ids");
        console.log(response.data.user_assets);
        setUserAssets(() => {return response.data.user_assets;});
      }
      catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [userPSQL]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await WillMaker.get(`/${userPSQL.id}/allocations`);
        console.log(`AssetAllocation.jsx --> useEffect 3 --> after call to WillMaker.get /${userPSQL.id}/allocations`);
        console.log(response.data.allocations);
        setUserAllocations(() => {return response.data.allocations;});
      }
      catch (err) {
        console.log(err)
      }
    }
    fetchData();
  }, [userPSQL,allocations]);


  return (
    <div className='text-center mt-10 '>
      <h1 className="font-bold text-4xl">Let's Allocate the Assets</h1>
    <button onClick={togglePopup} className='px-4 py-3 bg-[#5a17ee] text-white font-black rounded-md'>Instructions</button>
      {isPopupVisible && (
    
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:w-[50%] sm:h-[50%] w-[95%] h-[60%] flex justify-center flex-col items-center z-50 bg-white p-8 rounded-lg shadow-2xl border-4 border-gray-600">
  <button onClick={togglePopup} className='px-4 py-3 bg-[#5a17ee] text-white font-black rounded-md absolute right-2 top-2'>X</button>
  <div>
    <p className='font-bold text-xl mb-10'>Tip: Assets should be in total of 100%</p>
  </div>
    <div className="flex items-center mb-3">
      <div className="w-8 h-8 rounded-full bg-red-600 flex items-center justify-center text-white font-bold text-2xl mr-2">
        !
      </div>
      <span className="text-gray-700 font-semibold text-lg">
        Overallocated
      </span>
    </div>
    <div className="flex items-center mb-3">
      <div className="w-8 h-8 rounded-full bg-emerald-500 flex items-center justify-center text-white font-bold text-2xl mr-2">
        ✓
      </div>
      <span className="text-gray-700 font-semibold text-lg">
        Fully Allocated
      </span>
    </div>
    <div className="flex items-center">
      <div className="w-8 h-8 rounded-full bg-yellow-400 flex items-center justify-center text-white font-bold text-2xl mr-2">
        ▲
      </div>
      <span className="text-gray-700 font-semibold text-lg">
        Not Fully Allocated
      </span>
    </div>

    <button onClick={togglePopup} className='px-12 py-4 bg-[#5a17ee] text-white font-black rounded-full mt-10'>OK! Go it</button>
  
</div>
      )}


      <ul>
        {userAssets &&
          userAssets.map((userAsset) => {
            return (
              <div key={userAsset.id} className='flex justify-center'>
                <li key={userAsset.id}>
                  <AllocationBy
                    by="asset"
                    user_asset_id={userAsset.id}
                    icon={userAsset.asset_icon}
                    type={userAsset.asset_type}
                    label={userAsset.label}
                    onAssetStatusChange={handleAssetStatusChange}
                    onRefresh={onRefresh}
                  />
                </li>
              </div>
            );
            return null;
          })}

      </ul>



      <ButtonForward Text="Next" handleNext={handleNext} />
      <ButtonBack Text="Back" handleGoBack={handleGoBack} />
    </div>
  )
}


export default AssetAllocation


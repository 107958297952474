import { GraphQLClient, gql } from 'graphql-request';


export const graphcms = new GraphQLClient(process.env.REACT_APP_HYGRAPH_APP_API_KEY)
// export const graphcms = new GraphQLClient('https://api-ap-south-1.hygraph.com/v2/clm3jhqy828zd01tffbaa2cw9/master');

const category = `
  id,
  name,
  slug,
  color {css}
`

const comment = `
  id,
  name,
  createdAt,
  comment
`

const post = `
  id,
  title,
  slug,
  author {id, name, photo {url}, bio},
  featuredImage { url },
  excerpt,
  createdAt,
  updatedAt
  `

  export const QUERY_CATEGORIES = gql`
  {
    categories(){
        name,
        slug
    }
  }`

  export const QUERY_POSTS = gql`
  {
    posts(orderBy: updatedAt_DESC, first:4){
        ${post}
        content {raw}
        categories(){
            ${category}
        }
    }
  }`


export const QUERY_POSTS_BY_CATEGORY = gql`
query GetPostsByCategory($slug: String!) {
  posts(
    orderBy: updatedAt_DESC, 
    where: {categories_some: {slug: $slug}},
    first:4
    ){
      ${post}
      content {raw}
      categories(){
          ${category}
      }
  }
}`



export const QUERY_POST_SINGLE = gql`
query GetPostsByCategory($slug: String!) {
  posts(
    where: {slug: $slug},
    ){
      ${post}
      categories(){
          ${category}
      
      }
      content {raw }
  }
}`


export const QUERY_POSTS_RECENT = gql` {
  posts(
    orderBy: updatedAt_DESC, 
    first:4
    ){
      id,
      title,
      slug,
      featuredImage {url},
      createdAt
      }
}`


export const QUERY_POSTS_SIMILAR = gql` 
query GetPostsByCategory($slug: String!, $categories: [String!]){
  posts(
    orderBy: updatedAt_DESC,
    where: { slug_not: $slug, AND: {categories_some: {slug_in: $categories}}}
    first:4
    ){
      id,
      title,
      slug,
      featuredImage {url},
      createdAt
      }
}`

export const QUERY_COMMENTS = gql`
query GetComments($slug: String!){
  comments(
    orderBy: updatedAt_DESC,
    where: { post: {slug: $slug }})
  {
    ${comment}
  }
}
`






import React, { useState ,useContext} from 'react';
import { MdOutlineFeedback } from 'react-icons/md';
import WillMaker from "../apis/WillMaker";
import { WillContext } from "../context/WillContext";
import { toast, Toaster } from "react-hot-toast";
import { AssetIcon } from "../lib/AssetIcon";

const AssetDialog = ({asset}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { userPSQL, userAssets, setUserAssets} = useContext(WillContext);
  const LABEL_SIZE = 21;

  const [inputValues, setInputValues] = useState({
    label: "",
  });

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleAssetSelect =  async() => {
    // Code to submit the asset selection to the backend data.
    // try {
        
    //     await WillMaker.post(`/${userPSQL.id}/feedback/`, {
    //       "id": userPSQL.id,
    //       "pageName": inputValues.pageName,
    //       "comments": inputValues.comments,
          
    //     });
        
    // }
    //   catch (err) {
    //     console.log('err in handleAddBeneficiary');
    //     console.log(err);
    //   }
    //   toast.success('Feedback received. We appreciate your valuable input!');
    try {
      console.log(`AssetDialog.jsx --> handleAssetSelect --> WillMaker.post(/${userPSQL.id}/user_assets/; id: ${asset.id})`);
      const response1 = await WillMaker.post(`/${userPSQL.id}/user_assets/`, {
        id: asset.id,
        label: inputValues.label,
      });
      console.log("AssetDialog.jsx --> handleAssetSelect --> after await WillMaker.post");
      console.log(response1.data.user_asset);
    }
    catch (err) {
      console.log("AssetDialog.jsx --> handleAssetSelect -->  error in WillMaker.post");
      console.log(err);
    }
    console.log("AssetDialog.jsx --> handleAssetSelect --> inputValues.label", inputValues.label);

    // Read in the most recent user_assets from the database
    // and update the userAssets context variable
    try {
      const response = await WillMaker.get(`/${userPSQL.id}/user_assets`)
      console.log("UserAssetsChecker --> useEffect 1", response.data.user_assets);
      console.log("UserAssetsChecker --> useEffect 1 --> calling setUserAssets");
      setUserAssets(() => {return response.data.user_assets});
      
      const assetIds = response.data.user_assets.map(user_asset => user_asset.asset_id);
      // setSelectedAssets(() => {
      //   console.log("UserAssetsChecker --> useEffect --> setSelectedAssets --> assetIds")
      //   console.log(assetIds);
      //   return assetIds;
      // });

      console.log("UserAssetsChecker --> useEffect 1 --> assetIds:", assetIds);
    } catch (error) {
      console.log(error);
    }
  
      
    handleClosePopup();
  };






  const handleLabelChange = (e) => {
    // Limit the label to LABEL_SIZE characters
    let value = e.target.value.slice(0, LABEL_SIZE);
    value = value.charAt(0).toUpperCase() + value.slice(1);
    setInputValues({ ...inputValues, label: value });
  };

  // const handleChange = (e) => {
  //   setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  // };

  const charCount = LABEL_SIZE - inputValues.label.length;

  return (
    <>
      <div 
      key = {asset.id}
      className="border rounded-lg w-[150px] h-[135px] flex flex-col items-center justify-center hover:shadow-lg border-gray-300"

      // className='flex items-center gap-2 cursor-pointer' 
      onClick={handleOpenPopup}>
        <AssetIcon icon={asset.icon} />
      <Toaster toastOptions={{ duration: 4000 }} />
        {/* <MdOutlineFeedback /> */}
        {asset.type}
      </div>

      {isPopupOpen && (
        <>
          {/* Blur effect on the background */}
          <div className='fixed top-0 left-0 w-full h-full backdrop-blur-lg'></div>

          {/* Feedback popup */}
          <div className='fixed top-0 left-0 w-full h-full flex justify-center items-center z-50'>
            <div className='bg-white p-6 rounded-lg shadow-md md:w-1/2 w-full'>
              <h2 className='text-xl font-bold mb-4'>{asset.type}</h2>
              {/* <label className='block mb-4'>
                Select Page
                <select
                  className='block w-full border border-gray-300 rounded p-2'
                  name='pageName'
                  value={inputValues.pageName}
                  onChange={handleChange}
                >
                  <option value='User Profile'>User Profile</option>
                  <option value='Asset Selection'>Asset Selection</option>
                  <option value='Add Beneficiary'>Add Beneficiary</option>
                  <option value='Add Executor'>Add Executor</option>
                  <option value='Asset Allocation'>Asset Allocation</option>
                  <option value='Review'>Review</option>
                  <option value='Pdf Generation'>Pdf Generation</option>
                </select>
              </label> */}

              <label className='block mb-4'>
                 Please add a short label for this asset
                 </label>
                 {/* <input className="shadow appearance-none border rounded w-96 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="comments"
                    name="comments"
                    value={inputValues.comments}
                    onChange={handleLabelChange}
                    type="text"
                    placeholder="Label" 
                      required
                    /> */}
                <textarea
                  className='block w-full border border-gray-300 rounded p-2'
                  name='comments'
                  value={inputValues.comments}
                  onChange={handleLabelChange}
                  rows={1}
                  maxLength={LABEL_SIZE}
                  placeholder='Label'
                />
                <div className='text-gray-500 text-right mt-2'>
                  {charCount}/{LABEL_SIZE}   characters
                </div>
              {/* </label> */}

              <div className='flex justify-end'>
                <button
                  className='bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 mr-2 rounded'
                  onClick={handleClosePopup}
                >
                  Cancel
                </button>
                <button
                  className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded'
                  onClick={handleAssetSelect}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AssetDialog;

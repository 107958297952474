import React from 'react'
import Navigation from '../components/Navigation';
import { Helmet } from 'react-helmet-async';
import Footer from '../components/Footer';
import { PostsContainer } from '../components/posts';
import BlogNavigation from '../components/BlogNavigation';


const Blog = () => {
  return (
    <div>
    <Helmet>
    <title>Blog | wowwill</title>
    <meta name='description' content='Blog on everything related to will making - cheap, free, needed, witness, notary, registration, testator'/>
    <link rel="canonical" href="https://wowwill.in/posts" />
    
    </Helmet>
    <Navigation/>
    {/* <BlogNavigation /> */}

    <PostsContainer />
    
    <Footer/>
</div>
  )
}

export default Blog



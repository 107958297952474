import React from 'react'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'
import { NavLink } from 'react-router-dom'

const RefundPolicy = () => {
  return (
    <div>
        <Navigation/>
        <div className='mt-20 h-screen'>
        <h1 style={{ fontFamily: 'Noto sans' }} className='font-bold text-4xl text-center my-8'>Cancellation and Refund Policy</h1>
        <p className='text-xl text-center my-20'>The Cancellation and Refund Policy is as outlined in <NavLink to='/terms' className='font-bold'>Terms and Conditions</NavLink></p>
        </div>
        
         
        <Footer/>
    </div>
  )
}

export default RefundPolicy
import React from 'react'
import { BiTimeFive, BiPencil, BiSolidMagicWand, BiLike } from 'react-icons/bi'

import { FaRupeeSign } from 'react-icons/fa'
import { GrSecure } from 'react-icons/gr'


const Benefits = () => {
    return (
        <div className='bg-[#5a17ee] text-white py-10 mb-35 mt-10'>
            <h1 style={{ fontFamily: 'Noto sans' }} className='text-4xl md:text-5xl font-bold text-center'>Why use wowwill?</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mt-20 justify-items-left">
                <div className='flex flex-row items-center ml-8'>
                    <div className="flex flex-col items-center">
                        <div className="bg-white p-4 w-[102px] h-[100px] rounded-[20px]">
                            <FaRupeeSign className='text-[#5a17ee] mx-auto mt-4 text-3xl' />
                        </div>
                        <span className='mt-4'>10x Affordable</span>
                    </div>
                    <p className="bg-[#5a17ee] ml-6 rounded-[10px] mb-6 h-[110px] w-[300px] md:text-2xl">Very cheap. Make your will at a small token price.</p>
                </div>

                <div className='flex flex-row items-center ml-8'>
                    <div className="flex flex-col items-center">
                        <div className="bg-white p-4 w-[102px] h-[100px] rounded-[20px]">
                            <BiTimeFive className='text-[#5a17ee] mx-auto mt-4 text-3xl' />
                        </div>
                        <span className='mt-4'>Save Time</span>
                    </div>
                    <p className="bg-[#5a17ee] ml-6 rounded-[10px] mb-6 h-[110px] w-[300px] md:text-2xl">Easy-Peasy in a Jiffy</p>
                </div>

                <div className='flex flex-row items-center ml-8'>
                    <div className="flex flex-col items-center">
                        <div className="bg-white p-4 w-[102px] h-[100px] rounded-[20px]">
                            <BiPencil className='text-[#5a17ee] mx-auto mt-4 text-3xl' />
                        </div>
                        <span className='mt-4'>Free Updates</span>
                    </div>
                    <p className="bg-[#5a17ee] ml-6 rounded-[10px] mb-6 h-[110px] w-[300px] md:text-2xl">Reasses, Redistribute</p>
                </div>

                <div className='flex flex-row items-center ml-8'>
                    <div className="flex flex-col items-center">
                        <div className="bg-white p-4 w-[102px] h-[100px] rounded-[20px]">
                            <GrSecure className='text-[#5a17ee] mx-auto mt-4 text-3xl' />
                        </div>
                        <span className='mt-4'>Secure Data</span>
                    </div>
                    <p className="bg-[#5a17ee] ml-6 rounded-[10px] mb-6 h-[110px] w-[300px] md:text-2xl">Private and Confidential</p>
                </div>

                <div className='flex flex-row items-center ml-8'>
                    <div className="flex flex-col items-center">
                        <div className="bg-white p-4 w-[100px] h-[100px] rounded-[20px]">
                            <BiSolidMagicWand className='text-[#5a17ee] mx-auto mt-4 text-3xl' />
                        </div>
                        <span className='mt-4'>Customization</span>
                    </div>
                    <p className="bg-[#5a17ee] ml-6 rounded-[10px] mb-6 h-[110px] w-[300px] md:text-2xl">Infinite options</p>
                </div>

                <div className='flex flex-row items-center ml-8'>
                    <div className="flex flex-col items-center">
                        <div className="bg-white p-4 w-[102px] h-[100px] rounded-[20px]">
                            <BiLike className='text-[#5a17ee] mx-auto mt-4 text-3xl' />
                        </div>
                        <span className='mt-4'>Ease of Use</span>
                    </div>
                    <p className="bg-[#5a17ee] ml-6 rounded-[10px] mb-6 h-[110px] w-[300px] md:text-2xl">Piece of cake.<br/>You can do it!</p>
                </div>

            </div>


        </div>
    )
}

export default Benefits
import React from "react";
import UserSignUpWithPhoneNUmber from "./UserSignUpWithPhoneNumber";
import Benefits from "./Benefits";



const HeroContent = () => {
  return (
    <div className="mx-auto pt-8">
      <div className="mt-8 text-center container  ">
        
          <h1 style={{fontFamily:'Noto sans'}} className="text-4xl md:text-7xl font-extrabold text-gray-900 leading-tight mb-6">Your Will <br></br> in Minutes</h1>
          <p className="mt-2 text-lg md:text-xl max-w-[600px] mx-auto text-gray-600 tracking-wide mb-8">Effortlessly Create Customized Wills to Safeguard Your Assets and Provide for Your Family's Well-Being</p>
          <UserSignUpWithPhoneNUmber />
      </div>
      <div>
        <Benefits/>
      </div>
    </div>
  );
};

export default HeroContent;

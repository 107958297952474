import React, { useState } from 'react';

import { toast, Toaster } from "react-hot-toast";
import Navigation from '../components/Navigation';
import axios from "axios";
import Footer from '../components/Footer';
import LawyerImg from '../Assets/Lawyer.png'
import CaImg from '../Assets/CA.png'
import AgentImg from '../Assets/Agent.png'
const Registrations = () => {

  // const { userPSQL} = useContext(WillContext);

  const [inputValues, setInputValues] = useState({
    clientName: "",
    phone_number: "",
    profession: "",
    bussinessName: "",
    email: "",
    comments: "",
  });

  const handleSubmitFeedback = async () => {
    // Your code to submit the feedback data to the backend API goes here.
    try {

      await axios.post("api/v1/register", {

        "clientName": inputValues.clientName,
        "comments": inputValues.comments,
        "bussinessName": inputValues.bussinessName,
        "email": inputValues.email,
        "phone_number": inputValues.phone_number,
        "profession": inputValues.profession,

      });

    }
    catch (err) {
      console.log('err in Feedback fetching');
      console.log(err);
    }
    toast.success('You are registered!');

  };


  const handleCommentsChange = (e) => {
    // Limit the comments to 500 characters
    const value = e.target.value.slice(0, 500);
    setInputValues({ ...inputValues, comments: value });
  };

  const handleTextChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })
  }

  const handleChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  const charCount = 500 - inputValues.comments.length;
  return (
    <div>
      <Navigation />
      <Toaster toastOptions={{ duration: 4000 }} />

      <div>
        <div className=' max-w-[900px] mx-auto'>
          <h1 className='text-5xl text-center mt-20 font-black leading-snug'>Our Company is Expanding Rapidly🚀, and There's Plenty of Exciting Work Ahead. We Want You to Be a Part of It!</h1>
        </div>
        <div className='flex flex-wrap justify-evenly my-20'>
          <div className='shadow-lg p-5 max-w-[30%]'>
            <img className='mx-auto' src={LawyerImg} height='200px' width='200px' alt='Lawyer' />
            <h2 className='font-bold text-xl text-center'>Lawyer</h2>
            <p className='mt-5'>Please grow your Bussiness by providing expertise in will making to our customers</p>
          </div>
          <div className='shadow-lg p-5 max-w-[30%]'>
            <img className='mx-auto' src={CaImg} height='200px' width='200px' alt='Chartered Accountant' />
            <h2 className='font-bold text-xl text-center'>Chartered Accountant</h2>
            <p className='mt-5'>Please grow your Bussiness by helping our customers manage and grow their assets</p>
          </div>
          <div className='shadow-lg p-5 max-w-[30%]'>
            <img className='mx-auto' src={AgentImg} height='200px' width='200px' alt='Agent' />
            <h2 className='font-bold text-xl text-center'>Agent</h2>
            <p className='mt-5'>Please grow your Bussiness by helping our customers execute their wills</p>
          </div>

        </div>
      </div>
      <div className='w-full h-full flex justify-center items-center my-10 '>
        <div className='bg-white p-6 rounded-lg shadow-md md:w-1/2 w-full'>
          <h2 className='text-2xl font-bold mb-4 text-center'>Register With us</h2>

          <label className='block'>
            Are you a: <span className='text-sm text-gray-500'>(Required)</span></label>
          <select
            className='block w-full border border-gray-300 rounded p-2 mb-4'
            name='profession'
            value={inputValues.profession}
            onChange={handleChange}
          >
            <option value='Lawyer'>Lawyer</option>
            <option value='Chartered Accountant'>Chartered Accountant</option>
            <option value='Agent'>Agent</option>


          </select>
          <label className='block'>Full Name <span className='text-sm text-gray-500'>(Required)</span></label>
          <input
            className='block w-full border border-gray-300 rounded p-2 mb-4'
            placeholder='Full Name'
            name='clientName'
            type='text'
            value={inputValues.clientName}
            onChange={handleTextChange}
          />
          <label className='block'>Mobile Number <span className='text-sm text-gray-500'>(Required)</span></label>
          <input
            className='block w-full border border-gray-300 rounded p-2 mb-4'
            placeholder='8524564654'
            name='phone_number'
            type='number'
            value={inputValues.phone_number}
            onChange={handleTextChange}
          />

          <label className='block'>Bussiness Name</label>
          <input
            className='block w-full border border-gray-300 rounded p-2 mb-4'
            placeholder='Bussiness Name'
            name='bussinessName'
            type='text'
            value={inputValues.bussinessName}
            onChange={handleTextChange}
          />

          <label className='block'>Email</label>
          <input
            className='block w-full border border-gray-300 rounded p-2 mb-4'
            placeholder='Bussiness Name'
            name='email'
            type='email'
            value={inputValues.email}
            onChange={handleTextChange}
          />


          <label className='block mb-4'>
            Your comments
            <textarea
              className='block w-full border border-gray-300 rounded p-2'
              name='comments'
              value={inputValues.comments}
              onChange={handleCommentsChange}
              rows={4}
              maxLength={500}
              placeholder='Enter your feedback...'
            />
            <div className='text-gray-500 text-right mt-2'>
              {charCount}/500 characters
            </div>
          </label>

          <div className='flex justify-center'>

            <button
              className='bg-[#5a17ee] hover:opacity-80 text-white font-bold py-2 px-4 rounded'
              onClick={handleSubmitFeedback}
            >
              Count Me In
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Registrations
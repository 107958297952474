import React, { useEffect, useRef, useState,useContext } from "react";
import WillMaker from "../apis/WillMaker";
import { WillContext } from '../context/WillContext';

const SliderAllocation = ({userAssetAllocation, onRefresh}) => {

    const { userPSQL, setAllocations} = useContext(WillContext);
// const SliderAllocation = ({index, allocated_percentage, beneficiary_name}) => {

const [value, setValue] =  useState(0);
const timerRef = useRef();

const deferredCall =  async(value, id) => {
    console.log(`SliderAllocation.jsx --> deferredCall, value:${value}, id:${id}`);
    setValue(() => {return value;});

    // Write the new allocated value to the database
    // Update the current allocation
    try {
        const response1 = await WillMaker.put(`/allocations/${id}/`,
        {"allocated_percentage": value,
        });
        console.log(`SliderAllocation.jsx --> deferredCall --> after call to WillMaker.put(/allocations/${id}/)`)
        console.log(response1);

        // const response = await WillMaker.get(`/${userPSQL.id}/allocations`);
        // console.log(`SliderAllocation.jsx --> deferredCall --> after call to WillMaker.get(/${userPSQL.id}/allocations)`);
        // console.log(response);
        // console.log(response.data.allocations);
        // console.log("SliderAllocation.jsx --> deferredCall --> about to setAllocations");
        // setAllocations(() => {return response.data.allocations});
        // console.log("SliderAllocation.jsx --> deferredCall --> about to onRefresh");

        // we now must refresh the asset allocation page - so that the
        // updated total percentages are redrawn
        console.log("SliderAllocation.jsx --> deferredCall --> about to onRefresh");
        onRefresh();
    }
    catch(err){
        console.log(`SliderAllocation.jsx --> deferredCall --> error in call to WillMaker.get(/${userPSQL.id}/allocations`);
        console.log(err);
    }


};

useEffect(() => {
    setValue(() => {return userAssetAllocation && userAssetAllocation.allocated_percentage;});
}, []);

const handleSliderChange = (event, id) => {


    setValue(() => {return event.target.value;});

    console.log(`SliderAllocation.jsx --> handleSliderChange,  id:${id}, value:${event.target.value}`);

    clearTimeout(timerRef.current);

    console.log("SliderAllocation.jsx --> handleSliderChange setTimeout");
    timerRef.current = setTimeout( () => {
        deferredCall(event.target.value, id);
    }, 500);

}

return (
    <>
    <input 
        id="beneficiary"
        name={userAssetAllocation && userAssetAllocation.asset_allocation_id}
        type="range" min="0" max="100"
        value={value}
        onChange={event => handleSliderChange(event, userAssetAllocation && userAssetAllocation.asset_allocation_id)}
        className="w-[300px] sm:w-[400px]"
  
        />
        <p className="-my-1.5 text-lg">
  Allocated
  <span className="font-extrabold relative">
    <span className="inline-block bg-purple-600 rounded-md mx-2 px-2 py-1 text-white">{value.toString()}%</span>
  </span>
  to {userAssetAllocation && userAssetAllocation.beneficiary_name}
</p>
    </>
);
}



export default SliderAllocation;
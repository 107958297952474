import React from 'react'
import moment from 'moment'

const PostDetail = ({ post }) => {


  const getContentFragment = (index, text, obj, type) => {
    let modifiedText = text;

    if (obj) {

      if (obj.bold) {
        modifiedText = (<b key={index}>{text}</b>);
      }
      if (obj.italic) {
        modifiedText = (<em key={index}>{text}</em>);
      }
      if (obj.underline) {
        modifiedText = (<u key={index}>{text}</u>)
      }


      switch (type) {
        
        case 'heading-two':
          return <h2 key={index} className='text-xl font-bold mb-4'>{modifiedText.map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>)}</h2>

        case 'heading-three':
          return <h3 key={index} className='text-xl font-semibold mb-4'>{modifiedText.map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>)}</h3>

        case 'heading-four':
          return <h4 key={index} className='text-md font-semibold mb-4'>{modifiedText.map((item, i) => <React.Fragment key={i}>{item}</React.Fragment>)}</h4>

        case 'bulleted-list':
          console.log("PostDetail --> getContentFragment --> type = ", type)
          console.log(obj);
          return <ul key={index} className='text-md mb-4'>{
            obj.children.map((typeobj, index) => {
              const children = typeobj.children.map((item, itemIndex) => getContentFragment(itemIndex, item.text, item))
              return getContentFragment(index, children, typeobj, typeobj.type)            
            })
            }</ul>

          case 'numbered-list':
            console.log("PostDetail --> getContentFragment --> type = ", type)
            console.log(obj);
            return <ol key={index} className='text-md mb-4 list-decimal'>{
              obj.children.map((typeobj, index) => {
                const children = typeobj.children.map((item, itemIndex) => getContentFragment(itemIndex, item.text, item))
                return getContentFragment(index, children, typeobj, typeobj.type)            
              })
              }</ol>            

        case 'list-item':
          console.log("PostDetail --> getContentFragment --> type = ", type)
          console.log(obj);
          return <li key={index} className='text-md mb-4'>{
            obj.children.map((typeobj, index) => {
              const children = typeobj.children.map((item, itemIndex) => getContentFragment(itemIndex, item.text, item))
              return getContentFragment(index, children, typeobj, typeobj.type)            
            })
            }</li>

          case 'list-item-child':
            console.log("PostDetail --> getContentFragment --> type = ", type);
            console.log(index);
            console.log(modifiedText);
            console.log(obj);
            // return <p key={index}>{
            //   (typeof text === 'undefined' || text === null) ? (obj.children.map((typeobj, index) => {
            //     const children = typeobj.children.map((item, itemIndex) => getContentFragment(itemIndex, item.text, item))
            //     return getContentFragment(index, children, typeobj, typeobj.type)            
            //   })) : modifiedText.map((item, i) => <React.Fragment key={i}>{`dhishoom`}</React.Fragment>)
            //   }</p>  
            // return <p key={index} className='mb-8'>{modifiedText.map((item, i) => <React.Fragment>{modifiedText}</React.Fragment>)}</p>
            return <React.Fragment key={index}>{
              
              obj.children ? (obj.children?.map((typeobj, index) => {
                console.log("PostDetail --> getContentFragment --> type2 = ", type);
                console.log("typeobj");
                const children = typeobj.children?.map((item, itemIndex) => getContentFragment(itemIndex, item.text, item))
                console.log("Abc");
                return getContentFragment(index, children, typeobj, typeobj.type) || modifiedText.map((item, i) => <React.Fragment>{item}</React.Fragment>);           
              })) : modifiedText.map((item, i) => <React.Fragment>{item}</React.Fragment>)
              }</React.Fragment>  
              
          case 'paragraph':
            console.log("PostDetail --> getContentFragment --> type = ", type)
          return <p key={index} className='mb-8'>{modifiedText.map((item, i) => <React.Fragment>{item}</React.Fragment>)}</p>

        case 'image':
          return (
            <img
              key={index}
              alt={obj.title}
              height={obj.height}
              width={obj.width}
              src={obj.src}
            />
          );

        default:
          console.log("PostDetail --> getContentFragment --> default --> type = ", type)
          console.log("PostDetail --> getContentFragment --> default --> modifiedText = ", modifiedText)
          return modifiedText

      }
    }


  }

  return (
    // <div key={post.id}
    //     className='justify-self-center bg-white shadow-lg rounded-lg p-0 lg:p-8 pb-12 mb-8'
    // >
    //     <NavLink to={`/posts/article/${post.slug}`}>
    //         <div className='justify-self-center relative overflow-hidden shadow-md mb-6'>
    //             <img src={post.featuredImage.url}
    //                 className="justify-self-center justify-center shadow-lg rounded-t-lg" />
    //         </div>
    //         <h1 className='text-3xl text-center font-semibold mb-8 hover:text-pink-600'>{post.title}
    //         </h1>
    //     </NavLink>
    //     <div className="block text-center items-center justify-center mb-8">
    //         <div className="flex items-center justify-center mb-4 mr-8">
    //             <img alt={post.author.name}
    //                 height='30px'
    //                 width='30px'
    //                 className='align-center rounded-full'
    //                 src={post.author.photo.url} />
    //             <p className='inline align-middle text-gray-700 ml-2 text-lg'>{post.author.name}</p>
    //         </div>
    //         <div className="font-medium text-gray-700">
    //             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline mr-2 text-pink-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    //                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
    //             </svg>
    //             <span>
    //                 { moment(post.createdAt).format('DD MMM, YYYY') }
    //             </span>
    //         </div>
    //     </div>
    //     <p className='text-center text-lg text-gray-700 font-normal px-4 mb-8'>{post.excerpt}</p>
    //     <span>Post details go here</span>
    // </div>  

    <div className='bg-white shadow-lg rounded-lg lg:p-8 pb-12 mb-8'>
      <div className="relative overflow-hidden shadow-md mb-6">
        <img src={post.featuredImage.url} alt={post.title} className="object-top h-full w-full rounded-t-lg" />
      </div>
      <div className="px-4 lg:px-0">
        <div className="flex items-center mb-8 w-full">
          <div className="flex items-center justify-center mb-4">
            <img alt={post.author.name}
              height='30px'
              width='30px'
              className='align-center rounded-full'
              src={post.author.photo.url} />
            <p className='inline align-middle text-gray-700 ml-2 text-lg'>{post.author.name}</p>
          </div>
          <div className="font-medium text-gray-700 -mt-5 ml-16">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline mr-2 text-pink-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            <span>
              {moment(post.createdAt).format('DD MMM, YYYY')}
            </span>
          </div>
        </div>
        <h1 className="mb-8 text-3xl font-semibold">{post.title}</h1>
        {post.content.raw.children.map((typeobj, index) => {
          const children = typeobj.children.map((item, itemIndex) => getContentFragment(itemIndex, item.text, item))
          return getContentFragment(index, children, typeobj, typeobj.type)
        })}
      </div>
    </div>
  )
}

export default PostDetail
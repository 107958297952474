import React, { useContext, useEffect, useState } from "react";
import WillMaker from "../apis/WillMaker";
import { WillContext } from "../context/WillContext";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import ButtonForward from "./Button/ButtonForward";
import ButtonBack from "./Button/ButtonBack";
let updatedb = false;

const options = [
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
  { value: "Mstr.", label: "Mstr." },
  { value: "Dr.", label: "Dr." },
];

const UserProfile = ({ onSubmit, onGoBack }) => {

  const [inputValues, setInputValues] = useState({});
  const [dateValue, setDateValue] = useState('');

  const { userPSQL, setUserPSQL } = useContext(WillContext);

  

  const [personalDetailsVisible, setPersonalDetailsVisible] = useState(true);
  const [addressDetailsVisible, setAddressDetailsVisible] = useState(false);

  useEffect(() => {
    console.log("useEffect in UserProfile.jsx # 1");
    userPSQL && setInputValues(userPSQL);
   
  }, [userPSQL]);

  const isRequiredFieldsEmpty = () => {
    const { first_name, dob, middle_name, last_name } = inputValues;
    return (
      first_name === "" ||
      middle_name === "" ||
      last_name === "" ||
      dob === ""
    );
  };

  

  useEffect(() => {

    if(inputValues.dob){
      // Assuming your date string from PostgreSQL
    const dateString = inputValues.dob;
    
    // Create a Date object from the date string
    const dateObject = new Date(dateString);

    // Convert the Date object to an ISO string
    const isoString = dateObject.toISOString();

    // Set the ISO string as the value for the input field
    setDateValue(isoString.slice(0, 10)); // Use only the date part
    }
    

  }, [userPSQL,inputValues]);

  console.log(inputValues,"input");


  const handleNext = async () => {
    if (isRequiredFieldsEmpty()) {
      // Required fields are empty, handle accordingly
      console.log("Required fields are empty");
      alert("Please fill in all required fields");
    } else {
      // All required fields have values, proceed to next page
      setUserPSQL(inputValues);
      console.log("Saving data to database");
      try {
        await WillMaker.put(`/${userPSQL.id}`, inputValues);
      } catch (error) {
        console.log("error handleNext", error);
      }
      onSubmit(1);
    }
  };

  const handleInputChange = (e) => {


    let value = e.target.value;
    console.log("In handleInput change userProfile.jsx");
    console.log(value);

    if (e.target.type === "text") {
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }
    setInputValues({
      ...inputValues,
      // Capitalize the first letter for all text
      [e.target.name]: value,
    });
    console.log(inputValues.dob);
    updatedb = true;
  };

  const handleGoBack = () => {
    onGoBack(0);
  };

  console.log("in UserProfile.jsx #2");

  console.log("in UserProfile.jsx userPSQL", userPSQL);

  if (!userPSQL) {
    console.log("in UserProfile.jsx no userPSQL yet");
    return;
  }

  if (!inputValues) {
    console.log("in UserProfile.jsx no inputValues yet");
    return;
  }

  const togglePersonalDetails = () => {
    setPersonalDetailsVisible(!personalDetailsVisible);
  };

  const toggleAddressDetails = () => {
    setAddressDetailsVisible(!addressDetailsVisible);
  };

  return (
    <div className="w-full text-center max-w-2xl mx-auto mt-4">
      <h1 className="font-bold text-center text-4xl">
        Tell us a little about yourself
      </h1>
      <div className="flex flex-col gap-5 items-center justify-center mt-8">
        <div className="p-2 max-w-sm mx-auto bg-gray-100 shadow-md flex items-center space-x-4">
          <div>
            <p class="text-lg font-medium text-black">
              Phone Number: {`+${inputValues.phone_number}`}
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-6">
          <h2
            className="block text-gray-800 text-lg font-bold mb-4 cursor-pointer"
            onClick={togglePersonalDetails}
          >
            Personal Details{" "}
            {personalDetailsVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </h2>
          {personalDetailsVisible && (
            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="title"
                  >
                    Title
                  </label>
                  <select
                    className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="title"
                    name="title"
                    value={inputValues.title}
                    onChange={handleInputChange}
                  >
                    <option value="{value}">Select Title</option>
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="first_name"
                  >
                    First Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="first_name"
                    name="first_name"
                    value={inputValues.first_name}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="First Name"
                    required
                  />
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="middle_name"
                  >
                    Middle Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="middle_name"
                    name="middle_name"
                    value={inputValues.middle_name}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Middle Name"
                    required
                  />
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="last_name"
                  >
                    Last Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="last_name"
                    name="last_name"
                    value={inputValues.last_name}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Last Name"
                    required
                  />
                </div>
                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="dob"
                  >
                    Birth Date <span className="text-red-500">*</span>
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="dob"
                    name="dob"
                    value={dateValue}
                    onChange={handleInputChange}
                    type="date"
                    placeholder="Birth Date"
                    required
                  />
                </div>

                <div>
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    name="email"
                    value={inputValues.email}
                    onChange={handleInputChange}
                    type="email"
                    placeholder="Email"
                    required
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mb-6">
          <h2
            className="block text-gray-800 text-lg font-bold mb-4 cursor-pointer"
            onClick={toggleAddressDetails}
          >
            Address Details{" "}
            {addressDetailsVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </h2>
          {addressDetailsVisible && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="address_line_1"
                >
                  Address Line 1
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="address_line_1"
                  name="address_line_1"
                  value={inputValues.address_line_1}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Address Line 1"
                />
              </div>
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="address_line_2"
                >
                  Address Line 2
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="address_line_2"
                  name="address_line_2"
                  value={inputValues.address_line_2}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Address Line 2"
                />
              </div>
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="city"
                >
                  City
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="city"
                  name="city"
                  value={inputValues.city}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="City"
                />
              </div>
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="state"
                >
                  State
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="state"
                  name="state"
                  value={inputValues.state}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="State"
                />
              </div>
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="pincode"
                >
                  Pin Code
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="pincode"
                  name="pincode"
                  value={inputValues.pincode}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Pin Code"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-8">
        <ButtonForward Text="Next" handleNext={handleNext} />
        <ButtonBack Text="Back" handleGoBack={handleGoBack} />
      </div>
    </div>
  );
};

export default UserProfile;

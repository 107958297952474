import React from 'react'
import Navigation from '../components/Navigation'
import HeroContent from '../components/HeroContent'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase.config';
import { useAuthState } from "react-firebase-hooks/auth";
import { Helmet } from 'react-helmet-async';
import Footer from '../components/Footer';
import FaqsContainer from '../components/FaqsContainer';
import RegistrationAnnouncement from '../components/RegistrationAnnouncement';
import Newsletter from '../components/Newsletter';
const Home = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);

  useEffect(() => {
    console.log("User is: ", user);
      if (loading) return;
      if (user) return navigate("/user/dashboard");
      console.log("User Exist",user);
    }, [user, loading, navigate]);
  return (
    <div>
    <Helmet>
    <title>Homepage | wowwill</title>
    <meta name='description' content='wowwill is a platform for creating wills online. Secure your future and your loved ones well-being effortlessly with our intuitive online will creation service. '/>
    <link rel="canonical" href="https://wowwill.in/" />
    </Helmet> 
        <RegistrationAnnouncement/>
        <Navigation/>
        <HeroContent/>
        
        <FaqsContainer/>
        <Newsletter/>
        <Footer/>
       
    </div>
  )
}

export default Home
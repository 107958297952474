// Chatbot.js
import React, { useState } from 'react';
import { BsFillChatLeftTextFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { FaRobot } from "react-icons/fa";
const Chatbot = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [messages, setMessages] = useState([
    { id: 1, text: 'Hello! How can I help you?', isUser: false },
  ]);

  const [newMessage, setNewMessage] = useState('');

  const handleToggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;
  
    const userMessage = { role: 'user', content: newMessage };
  
    const updatedMessages = [...messages];
    updatedMessages.push({ id: Date.now(), text: newMessage, isUser: true });
  
    setMessages(updatedMessages);
    setNewMessage('');
  
    try {
      // Make an API call to send the user's message to the server
      const response = await fetch('/create-and-show-messages', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ content: newMessage }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to get response from the server');
      }
  
      const responseData = await response.json();
     console.log(responseData);
     console.log(responseData.data.data[0].content[0].text.value);
      // Simulate a response from the bot
      const botResponse = {
        id: Date.now() + 1,
        text: responseData.data.data[0].content[0].text.value, // assuming the response structure
        isUser: false,
      };
  
      // Update state with the bot's response
      setMessages((prevMessages) => [...prevMessages, botResponse]);
    } catch (error) {
      console.error('Error sending message to server:', error);
    }
  };
  

  return (
    <div className={`fixed bottom-0 right-4 ${isChatOpen ? 'sm:w-[400px] w-[300px]' : 'w-16'}`}>
    {isChatOpen && (
        <div className='text-white flex font-bold justify-center items-center gap-4 bg-[#5a17ee] rounded-t-xl p-4'>
        <FaRobot className='text-2xl'/>  Will Bot
          </div>
    )}
    
      <div className="flex flex-col h-[400px]">
        {isChatOpen && (
          <div className="flex-1 overflow-y-scroll p-4 bg-slate-50">

          
            {messages.map((message) => (
              <div
                key={message.id}
                className={`mb-4 ${message.isUser ? 'text-right' : 'text-left'}`}
              >
                <div
                  className={`py-2 px-4 rounded-lg inline-block ${
                    message.isUser ? 'bg-blue-500 text-white' : 'bg-gray-200'
                  }`}
                >
                  {message.text}
                </div>
              </div>
            ))}

            <div className='fixed bottom-10'>
            <input
                type="text"
                placeholder="Type your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                className="flex-1 py-2 px-4 border border-gray-300 rounded-full outline-none"
              />
              <button
                onClick={handleSendMessage}
                className="ml-1 bg-blue-500 text-white py-3 px-3 text-lg rounded-full"
              >
                <IoSend />
              </button>
            </div>
            
          </div>
        )}
        <div className="p-4 flex items-center">
          <button
            onClick={handleToggleChat}
            className={`ml-4 bg-[#5a17ee] text-white py-4 px-4 rounded-full fixed bottom-8 right-4`}
          >
            {isChatOpen ? <IoMdClose /> : <BsFillChatLeftTextFill className='text-xl'/>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;

import React, { useContext, useEffect, useState } from "react";
import WillMaker from "../apis/WillMaker";
import { WillContext } from "../context/WillContext";
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import ButtonForward from "./Button/ButtonForward";
import ButtonBack from "./Button/ButtonBack";
import { toast, Toaster } from "react-hot-toast";
const options = [
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
  { value: "Mstr.", label: "Mstr." },
  { value: "Dr.", label: "Dr." },
];



const AddWillExecutor = ({ onSubmit, onGoBack }) => {
  const { userPSQL,executor,setExecutor } = useContext(WillContext);
  const [disableAddExecutor, setDisableAddExecutor] = useState(true);
  const [alternateExecutorVisible, setAlternateExecutorVisible] = useState(false);
  const [inputValues, setInputValues] = useState({
    firstName: "",
    lastName: "",
    alternateExecutor:""
    
  });



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await WillMaker.get(`/${userPSQL.id}/executor`);
        console.log("fetching executor");
        console.log(response);
        // eslint-disable-next-line array-callback-return
        response.data.executor.map((executor) => {
          console.log(executor);
        });
        setExecutor(response.data.executor);

      }
      catch (err) {
        console.log(err);
      }
    }
    fetchData();

  }, []);

  const handleAddBeneficiary = async () => {
    setExecutor([...executor, inputValues]); // add the current input values to the beneficiaries array
    setInputValues({title:"", firstName: "", lastName: "", alternateExecutor:""}); // reset the input values

    // Wirte new beneficiary back to database

    try {
      // const response = await WillMaker.post(`/${userPSQL.id}/beneficiaries/`, {
      await WillMaker.post(`/${userPSQL.id}/executor/`, {
        "id": userPSQL.id,
        "title": inputValues.title,
        "first_name": inputValues.firstName,
        "last_name": inputValues.lastName,
        "alternate_executor": inputValues.alternateExecutor,
        
      });
      toast.success("Will Executor added successfully!",{
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        }});

      const updatedExecutor = await WillMaker.get(`/${userPSQL.id}/executor`);
      console.log("fetching executor");
      console.log(updatedExecutor);
      setExecutor(updatedExecutor.data.executor);
      

    }
    catch (err) {
      console.log('err in handleAddBeneficiary');
      console.log(err);
    }
}


const handleDeleteExecutor = async()=>{

          
          try {
            alert('You are about to remove will executor')
            await WillMaker.delete(`/${userPSQL.id}/executor`);
            toast.error("Will executor Deleted",{
              style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
              },
            });
            const updatedExecutor = await WillMaker.get(`/${userPSQL.id}/executor`);
            setExecutor(updatedExecutor.data.executor);
            
            
          } catch (err) {
            console.log('err in handleDeleteExecutor');
            console.log(err);
          }
}


  useEffect(() => {
    // Check if any of the required fields are empty
    const requiredFields = ["title", "firstName", "lastName", "relation"];
    const isAnyFieldEmpty = requiredFields.some((field) => inputValues[field] === "");
  
    // Enable the Add Beneficiary button if none of the required fields are empty
    setDisableAddExecutor(isAnyFieldEmpty || (executor && executor.length > 0));

  
  
  }, [inputValues,executor]);



  const handleNext = () => {
    onSubmit("beneficiary", executor);

  }


  const togglePersonalDetails = () => {
    setAlternateExecutorVisible(!alternateExecutorVisible);
  };

  const handleInputChange = (e) => {
    console.log("Beneficiaries --> handleInputChange: ", e.target.name, e.target.value);
    for (let key in inputValues) {
      console.log("Beneficiaries --> handleInputChange before: ", key, inputValues[key]);
    };
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    });


  };

 



  const handleGoBack = () => {
    onGoBack(0);
  };

  return (
    <div className="text-center mt-10 px-10">
    <Toaster
        toastOptions={{ duration: 4000 }}
        position="top-center"
        reverseOrder={false} />
      <h1 className="font-bold text-4xl">Add Will Executor</h1>
      {console.log("in beneficiary component")}
      <div className="flex justify-center ">
      <div className="blinking-circle mt-4 mr-2"></div>
      <p className="text-gray-500 mt-4">
      
      An executor is a person who is nominated by the testator of the will to carry out the instructions of the will
      </p>
      </div>
      <div className="flex flex-col mt-8">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
          <label className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="title">
                    Title
                  </label>
            <select className="block appearance-none w-full bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="title"
              name="title"
              value={inputValues.title}
              onChange={handleInputChange}>
              <option value="{value}">Select Title</option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div>
          <label className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="first_name">
                    First Name <span className="text-red-500">*</span>
                  </label>
            <input
              name="firstName"
              required
              value={inputValues.firstName}
              onChange={handleInputChange}
              placeholder="First name"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>


          <div>
          <label className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="last_name">
                    Last Name <span className="text-red-500">*</span>
                  </label>
            <input
              required
              name="lastName"
              value={inputValues.lastName}
              onChange={handleInputChange}
              placeholder="Surname"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          

          
          

        </div>
        <div className=" text-left">
          <h2 className="flex text-gray-800 text-lg font-bold mb-4 cursor-pointer items-center gap-2 mt-5" onClick={togglePersonalDetails}>
            Additional Information {alternateExecutorVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </h2>
            {alternateExecutorVisible && (
              <div>
              {/* <p>In the event that the above Executor were to predecease me, I hereby bequeath the following assets to the individual(s) mentioned below</p> */}
              <label className="block text-gray-700 text-sm font-bold my-2"
                    htmlFor="alternate_beneficiary">
                    Alternate Executor 
                  </label>
                  
              <input
              required
              name="alternateExecutor"
              value={inputValues.alternateExecutor}
              onChange={handleInputChange}
              placeholder="Name of Alternate Executor"
              className="shadow appearance-none border rounded md:w-1/2 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
              </div>
            )}
            </div>
        <div title={disableAddExecutor ? "Please enter all information about the beneficiary" : ""}>
          <button
            onClick={handleAddBeneficiary}
            disabled={disableAddExecutor ? true : false}
            className={`block mx-auto mt-5\
      bg-emerald-500 \
      ${disableAddExecutor ? 'opacity-40 cursor-not-allowed' : 'hover:opacity-80'} \
      font-semibold text-white text-xl px-24 py-3 rounded \
      `}
          >


            Add Will Executor
          </button>
        </div>
      </div>
      <div className="mt-8 text-left">
        <h2 className="font-bold text-2xl">Name of Will Executor</h2>
        {executor && executor.length === 0 ? (
          <p className="text-gray-500 text-xl">No Will Executor added yet</p>
        ) : (
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-6">
            {executor &&
                executor.map((executor) => (
                <li
                  key={executor.id}
                  className="bg-gray-800 text-white rounded-lg shadow-lg"
                >
                  <div className="p-6 flex items-center gap-4">
                    <div className="w-12 h-12 bg-blue-500 rounded-full flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 4a4 4 0 100 8 4 4 0 000-8zM12 14a7 7 0 00-7 7v1h14v-1a7 7 0 00-7-7z"
                        />
                      </svg>
                    </div>
                    <div>
                      <h3 className="text-lg font-bold">
                      {executor.title}    {executor.first_name} {executor.last_name}
                      </h3>
                      <p className="text-sm text-gray-300">
                      {executor.alternate_executor ? `Alternate executor: ${executor.alternate_executor}` : "No alternate executor specified"}
                
              </p>
                      
                    </div>
                  </div>
                  <div className="bg-gray-700 py-2 px-4 flex justify-end">
                    <button onClick={handleDeleteExecutor} className="text-red-500 font-semibold">Remove</button>
                  </div>
                </li>
              ))}
          </ul>
        )}
      </div>
      <div className="mt-8">
      <ButtonForward Text="Next" handleNext={handleNext} />
      <ButtonBack handleGoBack={handleGoBack} />
      </div>
    </div>

  );
};

export default AddWillExecutor;

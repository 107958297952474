import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { auth } from "../firebase.config";
import { useAuthState } from "react-firebase-hooks/auth";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
//components
import Sidebar from "../components/Sidebar";
import UserWelcomePage from "../components/UserWelcomePage";
import UserAssetsChecker from "../components/UserAssetsChecker";
import Beneficiaries from "../components/Beneficiaries";
import AssetAllocation from "../components/AssetAllocation";
import ReviewPage from "../components/ReviewPage/ReviewPage";
import UserProfile from "../components/UserProfile";
import WillMaker from "../apis/WillMaker";
import { WillContext } from "../context/WillContext";
import PdfViewer from "../components/PDFCreator/PdfViewer";
import AddWillExecutor from "../components/AddWillExecutor";
import FeedbackForm from "../components/FeedbackForm";

const UserDashboard = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(auth);

  const [currentQuestion, setCurrentQuestion] = useState(1); //default value will be one which will be used in switch

  const [toggleRefresh, setToggleRefresh] = useState(true); //default value will be one which will be used in switch

  const [answers, setAnswers] = useState([]); // answers will stored here
  const [open, setOpen] = useState(false);
  const { userPSQL, setUserPSQL, allocations, setAllocations } =
    useContext(WillContext);

  console.log(`${__filename} userpasql from user dashboard",${userPSQL}`);

  useEffect(() => {
    // Mimic a user for now
    console.log("UserDashboard.jsx --> useEffect 1 --> Enter");
    if (loading) {
      console.log("UserDashboard.jsx --> useEffect 1 --> 11");
      return;
    }

    if (!user) {
      console.log("UserDashboard.jsx --> useEffect 1 --> 17");
      return;
    }

    console.log(`UserDashboard.jsx --> useEffect 1 --> user: ${user}`);
    console.log(
      `UserDashboard.jsx --> useEffect 1 --> phoneNumber: ${user.phoneNumber}`
    );
    const cleanedPhoneNumber = user.phoneNumber.replace("+", "");

    const fetchData = async () => {
      try {
        console.log("UserDashboard.jsx -->  useEffect 1 --> fetchData --> 14");
        console.log(cleanedPhoneNumber);
        const response = await WillMaker.post(
          `/phonenumber/${cleanedPhoneNumber}/`
        );
        console.log(
          `UserDashboard.jsx --> useEffect 1 --> fetchData --> start post on /phonenumber/${cleanedPhoneNumber}/`
        );
        console.log(response);
        
       const utcDate = new Date(response.data.users[0].dob);
       response.data.users[0].dob = utcDate.toLocaleString();
      //  console.log("LocalDateString",localDateString);
        
        
        response.data.users[0].dob = response.data.users[0].dob.substring(0, 10);
        console.log(response);
        setUserPSQL(response.data.users[0]);
        console.log(userPSQL);
        // fetchData2(response.data.users[0].id);
        console.log(
          `UserDashboard.jsx --> useEffect 1 --> fetchData --> end post on /phonenumber/${cleanedPhoneNumber}/`
        );
      } catch (err) {
        console.log(`UserDashboard.jsx --> fetchData --> err: ${err}`);
      }
    };

    fetchData();
  }, [user, loading]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await WillMaker.get(`/${userPSQL.id}/allocations`);
        console.log("After call to get allocations");
        console.log(response);
        console.log(response.data.allocations);
        setAllocations(response.data.allocations);
        console.log("Allocations set");
      } catch (err) {
        console.log(err);
      }
    };

    const fetchData2 = async () => {
      try {
        const response = await WillMaker.get(`/${userPSQL.id}/allocations`);
        console.log("After call to get allocations");
        console.log(response);
        console.log(response.data.allocations);
        setAllocations(response.data.allocations);
        console.log("Allocations set");
      } catch (err) {
        console.log(err);
      }
    };

    if (userPSQL === null) {
      // Not ready yet - return
      console.log("12");
      return;
    }

    console.log("About to fetch beneficiary allocation in UserDashboard.jsx");

    fetchData();
    fetchData2();
  }, [userPSQL, setAllocations]);

  // useEffect( () => {
  //   console.log("useEffect in userDashboard.jsx #2");
  //   /* Will load the user allocation table, given that the user is
  //      set up in POstgreSQL */
  //      const user_id = userPSQL.id;
  //      const fetchData = () => {
  //       try {
  //         const response = WillMaker.get(`/users/${user_id}/asset_allocations`);
  //         console.log(response.data.asset_allocations);
  //         setAssetAllocations(response.data.asset_allocations);
  //       }
  //       catch (err) {
  //         console.log(err);
  //      }
  //      }
  //      console.log("About to fetch beneficiary allocation in UserDashboard.jsx");
  //      fetchData();
  //     }, [userPSQL] );



  const handleAnswer = (question, answer) => {
    const answerObj = {};
    answerObj[question] = answer;

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      ...answerObj,
    }));

    setCurrentQuestion(currentQuestion + 1);
  };

  const handleRefresh = () => {
    // setCurrentQuestion((currentQuestion) => currentQuestion);
    setToggleRefresh((currentRefresh) => !currentRefresh);
  };

  useEffect(() => {
    console.log(answers);
  }, [answers]);

  const handleGoBack = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  const Stepper = ({ currentStep }) => {
    return (
      <div>
        <ul className="flex mt-5 justify-center gap-10">
          {[...Array(6)].map((_, index) => {
            const stepNumber = index + 1;
            const isCompleted = stepNumber < currentStep;
            const isActive = stepNumber === currentStep;
            return (
              <li
                key={stepNumber}
                className={`flex items-center justify-center w-8 h-8 rounded-full ${
                  isCompleted
                    ? "bg-green-500 text-white"
                    : "bg-white text-gray-400"
                } ${
                  isActive
                    ? "border-2 border-green-500"
                    : "border-2 border-white"
                }`}
              >
                {stepNumber}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderCurrentQuestion = () => {
    switch (currentQuestion) {
      case 1:
        return <UserWelcomePage onSubmit={handleAnswer} />;
      case 2:
        return (
          <>
            <Stepper currentStep={1} />
            <UserProfile onSubmit={handleAnswer} onGoBack={handleGoBack} />
          </>
        );
      case 3:
        return (
          <>
            <Stepper currentStep={2} />
            <UserAssetsChecker
              onSubmit={handleAnswer}
              onGoBack={handleGoBack}
            />
          </>
        );
      case 4:
        return (
          <>
            <Stepper currentStep={3} />
            <Beneficiaries onSubmit={handleAnswer} onGoBack={handleGoBack} />
          </>
        );

      case 5:
        return (
          <>
            <Stepper currentStep={4} />
            <AddWillExecutor onSubmit={handleAnswer} onGoBack={handleGoBack} />
          </>
        );
      case 6:
        return (
          <>
            <Stepper currentStep={5} />
            <AssetAllocation
              onSubmit={handleAnswer}
              onGoBack={handleGoBack}
              answers={answers}
              onRefresh={handleRefresh}
            />
          </>
        );
      case 7:
        return (
          <>
            <Stepper currentStep={6} />
            <ReviewPage
              onSubmit={handleAnswer}
              onGoBack={handleGoBack}
              answers={answers}
            />
          </>
        );
      case 8:
        return (
          <>
            <PdfViewer
              onSubmit={handleAnswer}
              onGoBack={handleGoBack}
              answers={answers}
            />
          </>
        );
      default:
        return null;
    }
  };
  // to check if user exist if not redirect to "/" route
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
  }, [user, loading, navigate]);

  return (
    <div className="flex flex-row">
      <div>
        <Sidebar />
      </div>
      <div className="w-full md:w-4/5 flex-grow">
        <div className="flex gap-4 py-5 justify-end pr-10">
          <div className="flex items-center gap-2">
            <FeedbackForm />
          </div>

          <div>
            <NavLink
              to="/posts"
              className="flex w-full items-center px-3 py-2 font-bold hover:bg-gray-100"
            >
              Blog
            </NavLink>
          </div>
        </div>
        <hr className="text-black" />
        {/* <!-- {currentQuestion > 1 && <Stepper currentStep={currentQuestion} totalSteps={5} />} --> */}
        {renderCurrentQuestion()}
      </div>
    </div>
  );
};

export default UserDashboard;

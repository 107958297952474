import React from "react";

import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Font
} from "@react-pdf/renderer";


const styles = StyleSheet.create({
    page: {
      
      padding:'20px'
    },
    heading:{
        fontWeight:'bold',
        textAlign:'center',
    },
    text:{
        paddingVertical:'10px',
        fontSize:'16px'
    }
  });

  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
  });


const MyPDFDocument = ({ userPSQL, allocations, beneficiaries, assets, executor ,WatermarkText}) => {


    return (
        <Document>
            <Page style={styles.page}>
            

                <Text style={styles.heading}>Will</Text>
                <Text style={styles.text}>
                    I, {userPSQL.title} {userPSQL.first_name} {userPSQL.last_name} , do hereby revoke all my former Wills, Codicils and
                    Testamentary dispositions made by me. I declare this to be my last Will
                    and Testament,which I make on this .......(Date)..................... My Date of Birth is.............
                </Text>
                <Text style={{position:'absolute',color:'#979A9A', top: '40%', transform: 'rotate(-45deg)',fontSize:'56px',opacity:'40', transformOrigin: 'center' }}>{WatermarkText}</Text>
                <Text style={styles.text}>
                    I maintain good health, and possess a sound mind. This
                    Will is made by me of my own independent decision and free volition.
                    Have not be influenced, cajoled or coerced in any manner whatsoever.
                </Text>

                

                {beneficiaries && beneficiaries.map((beneficiary,index) => {
                    // loop thrugh each beneficiary
                    // list them only if they have at last one asset > than 0%
                    const beneficiary_allocations = allocations && allocations.filter(
                        allocation => ((allocation.beneficiary_id === beneficiary.id) &&
                            (allocation.allocated_percentage > 0))).sort(
                                (a, b) => (a.asset_id - b.asset_id)
                            );
                    if (beneficiary_allocations.length > 0) {
                        return (<div key={beneficiary.id}>
                            <Text> </Text>
                            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                <Text style={styles.text}>
                                   {index+1}{"."} {'I bequeath the following asset'}
                                    {beneficiary_allocations.length === 1 ? '' : 's'}
                                    {' to my '}
                                </Text>
                                <Text style={styles.text}>{`${beneficiary.relationship} ${beneficiary.first_name} ${beneficiary.last_name}`}
                                </Text>
                                  {beneficiary.alternate_beneficiaries && 
                                    <Text style={styles.text}>In the event {`${beneficiary.title} ${beneficiary.first_name} ${beneficiary.last_name}`} were to predecease me, then I bequeath the following assets to {`${beneficiary.alternate_beneficiaries}`}</Text>
                                  }
                               

                            </View>
                            {beneficiary_allocations.map((beneficiary_allocation) => {
                                return (
                                    <Text style={{fontSize:'16px'}}>

                                        {assets && assets.filter(asset => asset.id === beneficiary_allocation.asset_id)[0].type} ({beneficiary_allocation.label}) - {beneficiary_allocation.allocated_percentage}%
                                    </Text>
                                )
                            })}
                        </div>
                        )
                    }

                })}

                <Text style={{fontSize:'16px',marginTop:'30px'}}>
                    All the above assets are owned by me. No one else has rights on these properties.
                </Text>
                {executor[0] &&
                <Text style={{fontSize:'16px',marginTop:'50px',fontWeight:'bold'}}>
                
                    Executor of this will is {`${executor[0].title} ${executor[0].first_name} ${executor[0].last_name}`} and {`${executor[0].alternate_executor}`} is the alternate executor.
                </Text>
            }
                <Text style={{fontSize:'16px',marginTop:'50px'}}>
                    Signature of Testator
                </Text>
                <Text style={styles.text}>(Full name....)</Text>
                <Text style={styles.text}>Date:                           Place:</Text>
                <Text style={{ paddingVertical: '20px', textDecoration: 'underline', fontWeight: 'black',fontSize:'16px' }}>Witnesses</Text>
                <Text style={{fontSize:'16px'}}>We hereby attest that this Will has been signed by Shri.............as his last Will at .........(Place).........
                    in the joint presence of himself and us. The testator is in sound mind and made this Will without any coercion.</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', paddingVertical: '30px' }}>
                    <Text style={{fontSize:'16px'}}>Signature of Witness (1)                                   </Text>
                    <Text style={{fontSize:'16px'}}>Signature of Witness (2)                                   </Text>
                </View>
            </Page>
        </Document>
    )

}

export default MyPDFDocument;


import React, { useEffect, useState } from 'react';
import { graphcms, QUERY_CATEGORIES } from '../../Graphql/Queries';
import { NavLink } from 'react-router-dom';


const Categories = () => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {

    console.log("Categories.jsx: before graphcms query")

    graphcms.request(QUERY_CATEGORIES)
      .then(res => { console.log(res.categories); setCategories(res.categories) });
      console.log("Categories.jsx: after graphcms query")


  }, []);



  return (
    <div className='bg-white shadow-lg rounded-lg p-8 mb-8 pb-12'>
      <h3 className='text-xl mb-8 font-semibold border-b pb-4'>Categories</h3>
      {categories.map((category) => (
        <NavLink key={category.slug} to={`/posts/category/${category.slug}`}
          className="text-md">
            <span className='cursor-pointer block pb-3 mb-3'>
            {category.name}
            </span>
        </NavLink>
      ))}       </div>
  )
}

export default Categories
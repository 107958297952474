import React, { createContext, useState } from "react";

export const WillContext = createContext();

export const WillContextProvider = props => {

    const [userPSQL, setUserPSQL] = useState(null);
    const [userAssets, setUserAssets] = useState(null);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [assets, setAssets] = useState([]);
    const [allocations, setAllocations] = useState(null);
    const [executor, setExecutor] = useState(null);


    return (
        <WillContext.Provider value={{userPSQL, setUserPSQL, 
                                      userAssets, setUserAssets,
                                      beneficiaries, setBeneficiaries,
                                      assets, setAssets,
                                      allocations, setAllocations,
                                      executor, setExecutor
                                    }}>
        {props.children}
        </WillContext.Provider>
    );

}
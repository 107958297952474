import React, { useState } from 'react';
import {AiOutlineClose} from 'react-icons/ai'
import { NavLink } from 'react-router-dom';
const RegistrationAnnouncement = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleCloseClick = () => {
    setIsVisible(false);
  };

  return isVisible ? (
    <div className='bg-yellow-300 flex justify-center gap-5 items-center'>
      <p className=' font-bold '>ATTENTION: Certified Accountants, Lawyers, Agents!! Please <NavLink className='underline mx-0 px-0' to={'/register'}> Register</NavLink> and access exclusive benefits! 🚀</p>
      <button className='text-xl' onClick={handleCloseClick}>
      <AiOutlineClose/>
      </button>
    </div>
  ) : null;
};

export default RegistrationAnnouncement;

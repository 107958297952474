import React,{useContext} from "react";
import {PDFViewer, pdf} from "@react-pdf/renderer";
import { WillContext } from "../../context/WillContext";

import MyPDFDocument from "./MyPdfdocument";
import { saveAs } from "file-saver";
import ButtonBack from "../Button/ButtonBack";


const PdfViewer = ({ onGoBack }) => {



  const getDateTime = () => {
    const date = new Date();
    return (
      date.getDate() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getFullYear() +
      "-" +
      date.getHours() +
      "-" +
      date.getMinutes() +
      "-" +
      date.getSeconds()
    ); 
  }
  
  const handleDownload = async () => {
    const blob = await pdf(
      <MyPDFDocument userPSQL={userPSQL} allocations={allocations} beneficiaries={beneficiaries} assets={assets} executor={executor}/>
      ).toBlob();
    saveAs(blob, "will-" + getDateTime() + ".pdf");
  };



  const handleGoBack = () => {
    onGoBack(0);
  };
  // const { userPSQL, assets, setAssets, userAssets, setUserAssets, setAllocations } = useContext(WillContext);

  const {userPSQL,  allocations,beneficiaries,assets,executor,userAssets} = useContext(WillContext);

  console.log(userAssets,"userassets in pdf");
  console.log(allocations,"Allocations in pdf");
 

    const WatermarkText = 'Generated by wowwill';



  return (
    <div className="text-center items-center md:flex flex-row justify-center gap-20">
      <PDFViewer scale={0.5} style={{ width: "50%", height: "700px" }}>
        <MyPDFDocument userPSQL={userPSQL} allocations={allocations} beneficiaries={beneficiaries} assets={assets} executor={executor} WatermarkText={WatermarkText}/>
        
         
      
      </PDFViewer>

      {/* PDFDownloadLink suffers from a caching issue as described in 
      https://github.com/diegomura/react-pdf/issues/736#issuecomment-618536407
      
      Replaced PDFDownloadLink with the proposed solutions there using blob and saveAs*/}
      <div className="flex flex-col gap-5">

        <button
          onClick={handleDownload}
          className="hover:opacity-80 font-semibold text-[#3772ff] text-xl"
        >
          Download PDF Button
        </button>
        

        <ButtonBack handleGoBack={handleGoBack} />
      </div>
    </div>
  );
};

export default PdfViewer;

import React from 'react';
import Navigation from '../components/Navigation';
import { Helmet } from 'react-helmet-async';
import Footer from '../components/Footer';
import FaqsContainer from '../components/FaqsContainer';
const FAQs = () => {
 
  return (
    <div>
    <Helmet>
    <title>FAQs | wowwill</title>
    <meta name='description' content='Explore our comprehensive FAQs page for all your queries. Find answers to common questions about our services, products, and more. Get the information you need in one place.'/>
    <link rel="canonical" href="https://wowwill.in/faqs" />
    
 
    </Helmet>
    <Navigation/>
    <FaqsContainer/>
    <Footer/>
    </div>
  );
};

export default FAQs;

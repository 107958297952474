import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase.config";
import { IconContext } from "react-icons";
import WillMaker, { WillAssets } from "../apis/WillMaker";
import { WillContext } from "../context/WillContext";
import { AssetIcon } from "../lib/AssetIcon";
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import AssetDialog from "../lib/AssetDialog";
import ButtonForward from "./Button/ButtonForward";
import ButtonBack from "./Button/ButtonBack";

const UserAssetsChecker = ({ onSubmit, onGoBack }) => {
  const [selectedAssets, setSelectedAssets] = useState([]);
  const { userPSQL, assets, setAssets, userAssets, setUserAssets, setAllocations } = useContext(WillContext);
  const [user] = useAuthState(auth);

  const [userAssetsVisible, setUserAssetsVisible] = useState(true);
  const [systemAssetsVisible, setSystemAssetsVisible] = useState(true);

  const toggleUserAssetsVisible = () => { setUserAssetsVisible( () => {return !userAssetsVisible});};
  const toggleSystemAssetsVisible = () => {setSystemAssetsVisible( () => {return !systemAssetsVisible});  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await WillAssets.get("/");
        setAssets(response.data.assets);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await WillMaker.get(`/${userPSQL.id}/user_assets`)
        console.log("UserAssetsChecker --> useEffect 1", response.data.user_assets);
        console.log("UserAssetsChecker --> useEffect 1 --> calling setUserAssets");
        setUserAssets(() => {return response.data.user_assets});
        
        const userAssetIds = response.data.user_assets.map(user_asset => user_asset.id);
        setSelectedAssets(() => {
          console.log("UserAssetsChecker --> useEffect --> setSelectedAssets --> userAssetIds")
          console.log(userAssetIds);
          return userAssetIds;
        });

        console.log("UserAssetsChecker --> useEffect 1 --> assetIds:", userAssetIds);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

  }, []);




  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await WillMaker.get(`/${userPSQL.id}/user_assets`)
        console.log("UserAssetsChecker --> useEffect 3", response.data.user_assets);
        console.log("UserAssetsChecker --> useEffect 3 --> calling setUserAssets");
        setUserAssets(() => {return response.data.user_assets});
        
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

  }, [selectedAssets]);

  

  // console.log(assets);
  const handleAssetSelect = async (userAssetId) => {
    // e.preventDefault();

    console.log(`UserAssetsChecker --> handleAssetSelect --> selected asset is ${userAssetId}`);

    console.log(selectedAssets);

      console.log("UserAssetsChecker --> handleAssetSelect --> Deselect (asset is in the selected list)", userAssetId);

      setSelectedAssets((userAssetIds) => {
        // This id is now removed from the list of selected assets
        return userAssetIds.filter((id) => id !== userAssetId)
      });


      // Since the asset is deselected, we need to archive the user_asset record.
      // Note that we will not delete this prior allocation record.
      // We will just mark it as archived and not use it in the future.

      try {
        console.log(`UserAssetsChecker --> handleAssetSelect --> WillMaker.put(/${userPSQL.id}/user_assets/${userAssetId}`);

        // we do not have the user_asets id. We will use the combination of the user id and the asset id
        const response1 = await WillMaker.put(`/${userPSQL.id}/user_assets/${userAssetId}`);
        console.log("UserAssetsChecker --> handleAssetSelect --> after await WillMaker.put");
        console.log(response1.data.user_assets);

      }
      catch (err) {
        console.log("UserAssetsChecker --> handleAssetSelect --> error in Willmaker.put");
        console.log(err);
      }
  }; // end of handleAssetSelect



  const handleNext = () => {
    onSubmit("asset", selectedAssets);
  };

  const handleGoBack = () => {
    onGoBack(0);
  };

  return (
    <div className="text-center mt-10">
      <h1 className="font-bold text-4xl">Tell us about your Assets</h1>
      <p className=" text-gray-500 mt-4">
        This helps us make sure your situation is covered{" "}
      </p>

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-6">
          <br />
          <h1 className="block text-gray-800 text-2xl font-bold mb-4 cursor-pointer" onClick={toggleUserAssetsVisible}>
          {userAssetsVisible ? '' : "Expand to see "} Selected Assets {userAssetsVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </h1>
          {userAssetsVisible && (
      <div className="flex justify-center gap-10 flex-wrap mt-8">
      <IconContext.Provider value={{ size: "2em" }}>
        {userAssets && userAssets.map((userAsset) => (
          <div title="Remove asset. Any allocations to this asset will be removed!"
            key={userAsset.id}
            className="border rounded-lg w-[150px] h-[135px] flex flex-col items-center justify-center hover:shadow-lg
          bg-emerald-200 border-green-600"
            onClick={() => handleAssetSelect(userAsset.id)}
          >
            <AssetIcon icon={userAsset.asset_icon}></AssetIcon>
            <p className="mt-2">{userAsset.asset_type}</p>
            <p className="mt-2">{userAsset.label}</p>
          </div>
        ))}
      </IconContext.Provider>
    </div>
          )}
        </div>

        <div className="mb-6">
        <br />
        <br />
          <h1 className="block text-gray-800 text-2xl font-bold mb-4 cursor-pointer" onClick={toggleSystemAssetsVisible}>
            Make selections from the asset types {systemAssetsVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </h1>
          {systemAssetsVisible && (
      <div className="flex justify-center gap-10 flex-wrap mt-8">
      <IconContext.Provider value={{ size: "2em" }}>
        {assets && assets.map((asset) => (
          <AssetDialog asset={asset}/>
        ))}
      </IconContext.Provider>
    </div>
          )}
        </div>
      </div>

      <ButtonForward Text="Next" handleNext={handleNext} />
      <ButtonBack Text="Back" handleGoBack={handleGoBack} />
    </div>
  );
};

export default UserAssetsChecker;

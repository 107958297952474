import React, { useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { WillContext } from "../context/WillContext";
import WillMaker from "../apis/WillMaker";
import SliderAllocation from "./SliderAllocation";
import { AssetIcon } from "../lib/AssetIcon";


const AllocationBy = ({by, user_asset_id, icon, type, label, onRefresh,onAssetStatusChange}) => {

  const [userAssetAllocations, setUserAssetAllocations] = useState(null);
  const [userAssetTotalPercentage, setUserAssetTotalPercentage] = useState(0);
  

  useEffect ( () => {
    const fetchData = async () => {
      try {
        console.log(`AllocationBy.jsx --> useEffect 2 --> before call to get /user_assets/${user_asset_id}/allocations/`);
        const response = await WillMaker.get(`/user_assets/${user_asset_id}/allocations/`);
        console.log(`AllocationBy.jsx --> useEffect 2 --> after call to Willmaker.get(/user_assets/${user_asset_id}/allocations/)`);
        console.log(response.data.user_asset_allocations);
        setUserAssetAllocations(() => {return response.data.user_asset_allocations; });
        setUserAssetTotalPercentage(() => {return Number(response.data.total_percentage); });
      }
      catch (err) {
        console.log(`AllocationBy.jsx --> useEffect 2 --> in err call to Willmaker.get(/user_assets/${user_asset_id}/allocations/)`);
        console.log(err)
      }
    }
    
    fetchData();
  
}, []);

  useEffect ( () => {
    const fetchData = async () => {
      try {
        console.log(`AllocationBy.jsx --> useEffect 1 --> before call to get /user_assets/${user_asset_id}/allocations/`);
        const response = await WillMaker.get(`/user_assets/${user_asset_id}/allocations/`);
        console.log(`AllocationBy.jsx --> useEffect 1 --> after call to Willmaker.get(/user_assets/${user_asset_id}/allocations/)`);
        console.log(response.data.user_asset_allocations);
        // setUserAssetAllocations(() => {return response.data.user_asset_allocations; });
        setUserAssetTotalPercentage(() => {return Number(response.data.total_percentage); });
      }
      catch (err) {
        console.log(`AllocationBy.jsx --> useEffect 1 --> in err call to Willmaker.get(/user_assets/${user_asset_id}/allocations/)`);
        console.log(err)
      }
    }
    
    fetchData();
  
}, [onRefresh]);


useEffect(() => {
  // Call onAssetStatusChange when all assets are green
  console.log(`AllocationBy.jsx --> useEffect 3 --> userAssetTotalPercentage:${userAssetTotalPercentage})`);    
  if (userAssetTotalPercentage <= 100) {
    onAssetStatusChange(user_asset_id, true);    
  } else {
    onAssetStatusChange(user_asset_id, false);
  }
}, [userAssetTotalPercentage,userAssetAllocations])


  console.log(`AllocationBy.jsx, by:${by}, user_asset_id:${user_asset_id}, icon:${icon}, type:${type}`);


    return (

        <div className="mt-10">
          <div className="flex gap-10 flex-wrap sm:justify-start justify-center mt-8">
            <IconContext.Provider value={{size: "2em" }}>
                <div
                  className={`border rounded-lg w-[150px] h-[135px] flex flex-col items-center justify-center hover:shadow-lg 
                  ${userAssetTotalPercentage === 100 ? 'bg-emerald-200 border-green-600' 
                  : userAssetTotalPercentage < 100 ? 'bg-yellow-300 border-yellow-600' 
                  : 'bg-red-400 border-red-600'}`}>
                  <AssetIcon icon={icon}></AssetIcon>
                  <p className="mt-2">{type}</p>
                  <p className="mt-2">{label}</p>
              </div>
            </IconContext.Provider>
            <div>
              {userAssetAllocations && userAssetAllocations.map((userAssetAllocation, index) => {
                return (
                <div>
                  <SliderAllocation userAssetAllocation={userAssetAllocation} onRefresh={onRefresh}
                  onAssetStatusChange={onAssetStatusChange}
                  />
                  <br/>   
              </div>);  
            })}
            </div>
          </div>
        </div>
    );
}

export default AllocationBy;
import { GraphQLClient, gql } from 'graphql-request';

export const graphcms = new GraphQLClient(process.env.REACT_APP_HYGRAPH_APP_API_KEY, {
    headers: {
        authorization: `Bearer ${process.env.REACT_APP_HYGRAPH_APP_TOKEN}`
    }
});

const obj = `
    $name: String!,
    $email: String!,
    $comment: String!,
    $slug: String!,
`

const data = `
    name: $name,
    email: $email,
    comment: $comment,
    post: {
        connect: {slug: $slug}
    },  
`

export const CREATE_COMMENT = gql`
mutation CreateComment(${obj}) {
    createComment(data: { ${data}}) {
        id
    }
}
`



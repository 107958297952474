import React, { useState } from 'react';
import UnderlineSvg from '../Assets/Underline.svg';
import { ApiV1 } from '../apis/WillMaker';
import { toast, Toaster } from "react-hot-toast";

const Newsletter = () => {
  const [email, setEmail] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Change the URL to your server endpoint
      const response = await ApiV1.post('/newsletter', { email });
      toast.success("You are successfully added to our newsletter",{
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        }});
      console.log('Email sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <div className='text-center py-20'>
      <div className='max-w-[1000px] mx-auto'>
        <h2 className='font-black text-5xl leading-[4rem]'>
          <img src={UnderlineSvg} alt='underline' className='w-[37%] relative top-[5.5rem] left-[13.5rem]'/>
          Unlock a
          Wealth of advice from our online legal experts, conveniently delivered to your inbox every week!
        </h2>
        <form onSubmit={handleSubmit}>
          <input
            className='mt-5 px-8 py-4 m-3 rounded-full bg-slate-100'
            type='email'
            placeholder='Enter your email'
            value={email}
            onChange={handleEmailChange}
          />
          <button type='submit' className='bg-[#5a17ee] hover:opacity-80 px-8 py-4 rounded-full font-black uppercase text-white mt-10'>
            Yes, Please
          </button>
        </form>
      </div>
    </div>
  );
}

export default Newsletter;

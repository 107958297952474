import React from 'react'
import { NavLink } from 'react-router-dom'
import JoinUs from '../Assets/joinus.png'
const Footer = () => {
    return (
        <div className='bg-slate-800 px-8 text-white'>
            <hr />

            <div className='mt-10 flex max-w-[1200px] items-start justify-around mx-auto  sm:flex-wrap flex-nowrap'>
                <h1 className='text-xl md:text-3xl lg:text-4xl sm:text-left text-center font-black'>wowwill</h1>
                   <NavLink to='/register'>
                   <img src={JoinUs} height='300px' width='300px' alt='join-us'/>
                   </NavLink>
                    
                    <div>
                        
                        <ul className='font-light my-2 text-gray-400 flex flex-col gap-4 text-sm cursor-pointer'>
                            <li><NavLink to='/about'>About Us</NavLink></li>
                            <li><NavLink to='/privacy'>Privacy Policy</NavLink> </li>
                            <li><NavLink to='/terms'>Terms & conditions</NavLink></li>
                            <li><NavLink to='/refund'>Cancellation & refund policy</NavLink></li>
                        </ul>
                    </div>
                
            </div>
            <div className='py-20  text-center text-white'>© wowwill 2023</div>
        </div>
    )
}

export default Footer
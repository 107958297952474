import React from 'react'

const Author = ({ author }) => {
  return (
    <div className="text-center mt-10 mb-8 pb-6 pt-3 relative rounded-lg bg-slate-400 bg-opacity-20">
      <div className="absolute left-1/2 -top-8">
        <img src={author.photo.url} alt={author.name} height="48px" width="48px" className="align-middle rounded-full" />
      </div>
      <h3 className="my-4 text-xl font-bold">{author.name}</h3>
      <p className="text-lg">{author.bio}</p>
    </div>
  )
}

export default Author
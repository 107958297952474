import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { BsCheckCircleFill } from 'react-icons/bs';
import { MdClose } from 'react-icons/md'; // Import the close icon from React Icons
import { auth } from '../firebase.config';
import {AiOutlineMenu} from 'react-icons/ai'

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
      console.log('User signed out successfully');
      // Redirect or update state as needed
    } catch (error) {
      console.error('Error signing out user', error);
    }
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  return (
    <div className="flex flex-row h-screen">
  {/* Sidebar Toggle Button */}
  <button
    onClick={toggleMenu}
    type="button"
    className="fixed top-4 left-4 z-50 bg-[#5a17ee] p-2 rounded-full text-white"
  >
    {isOpen ? (
      <MdClose className="h-6 w-6" />
    ) : (
      <AiOutlineMenu className="h-6 w-6 text-white" />
    )}
  </button>

  <div
    className={`bg-gray-800 text-white w-64 h-full fixed ${
      isOpen ? 'left-0 transition-all duration-1000 ease-in-out' : '-left-64 transition-all duration-1000 ease-in-out' // Adjust the width and duration as needed
    }`}
    style={{
      zIndex: 999, // Ensure the sidebar is above other content
    }}
  >
    {/* Close Button Inside Sidebar */}
    <button
      onClick={closeSidebar}
      type="button"
      className={`absolute top-4 z-50 bg-[#5a17ee] p-2 rounded-full text-white ${
        isOpen ? 'left-64 transition-all duration-1000 ease-in-out' : 'left-0 transition-all duration-1000 ease-in-out'
      }`}
    >
      <MdClose className="h-6 w-6" />
    </button>

    {/* Sidebar content */}
    <div className="px-4 py-2 flex items-center mt-4">
      <BsCheckCircleFill className="text-[#3772FF] text-3xl mr-2" />
      <p className="text-3xl items-start flex gap-3 font-bold">
        wowwill{' '}
        <span
          className=" text-gray-100 px-2 py-1  rounded-full ml-2 text-xs font-semibold relative border border-gray-100 top-[-5px] left-0"
          style={{ transform: 'translate(-30%, 30%)' }}
        >
          Beta
        </span>
      </p>
    </div>
    <ul className="px-4 py-2">
      <li>
        <NavLink
          onClick={handleSignOut}
          className="gap-3 px-2 py-1 flex items-center rounded-md text-base font-medium hover:bg-gray-700"
        >
          Signout <FaSignOutAlt />
        </NavLink>
      </li>
    </ul>
  </div>
</div>

  );
}

export default Sidebar;

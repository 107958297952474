import OtpInput from "otp-input-react";
import { useState} from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import '../styles/captcha.css'



const UserSignUpWithPhoneNUmber = ({uid}) => {
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  
  const navigate = useNavigate();
  

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    setLoading(true);
    onCaptchVerify();

    const appVerifier = window.recaptchaVerifier;

    const formatPh = "+" + ph;

    signInWithPhoneNumber(auth, formatPh, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setLoading(false);
        setShowOTP(true);
        toast.success("OTP sent successfully!");
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  function onOTPVerify() {
    setLoading(true);
    window.confirmationResult
      .confirm(otp)
      .then(async (res) => {
        console.log(res);
        setUser(res.user);
        setLoading(false);
        toast.success("Logged in successfully");
        navigate("/user/dashboard");
        
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  return (
    
    <section className="flex items-center justify-center mx-auto rounded">
    
      <div>
        <Toaster toastOptions={{ duration: 4000 }} />
        <div id="recaptcha-container"></div>
        {user ? (
          <h2 className="text-center text-black font-medium text-2xl">
            👍Login Success
          </h2>
        ) : (
          <div className="w-80 flex flex-col gap-4 rounded-lg p-4">
            
            {showOTP ? (
              <>
                
                <label
                  htmlFor="otp"
                  className="font-bold text-xl text-black text-center"
                >
                  Enter your OTP
                </label>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}

                  otpType="number"
                  disabled={false}
                  autoFocus
                  className="opt-container"
                ></OtpInput>
                <button
                  onClick={onOTPVerify}
                  className="bg-gradient-to-r from-purple-500 to-[#5a17ee] hover:to-purple-600 py-5 text-xl text-white rounded-full"
                >
                  {/* {loading && (
                    <CgSpinner size={20} className="animate-spin" />
                  )} */}
                  <span>Verify OTP</span>
                </button>
              </>
            ) : (
              <>
                
                <PhoneInput country={"in"} value={ph} onChange={setPh}/>
                
                <button
                  onClick={onSignup}
                  className="bg-gradient-to-r from-purple-500 to-[#5a17ee] hover:to-purple-700 py-5 text-xl text-white rounded-full"
                >
                  {/* {loading && (
                    <CgSpinner size={20} className="animate-spin" />
                  )} */}
                  
                  <span>Get Started</span>
                  
                </button>
                <p className="text-sm font-light">**No Payment Required until Will Generation</p>
              </>
            )}
          </div>
        )}
      </div>
    </section>
  );
};



export default UserSignUpWithPhoneNUmber;



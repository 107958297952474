import React from 'react'
import {HiOutlineArrowNarrowRight} from 'react-icons/hi'

const ButtonForward = ({Text,handleNext}) => {
  return (
    <div>
      <button type="submit"
          onClick={handleNext}
          className="block mx-auto my-8 bg-[#5a17ee] hover:opacity-80 font-semibold text-white text-xl px-12 py-4 rounded-full "
        >
          {Text} <HiOutlineArrowNarrowRight className="inline-block ml-2 text-3xl" />
        </button>
    </div>
  )
}

export default ButtonForward
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import moment from 'moment';



const Post = ({ post }) => {

    console.log("In Post.jsx");

    return (
    <div key={post.id}
        className='justify-self-center bg-white shadow-lg rounded-lg p-0 lg:p-8 pb-12 mb-8'
    >
        <NavLink to={`/posts/article/${post.slug}`}>
            <div className='justify-self-center relative overflow-hidden shadow-md mb-6'>
                <img src={post.featuredImage.url}
                    className="justify-self-center justify-center shadow-lg rounded-t-lg" />
            </div>
            <h1 className='text-3xl text-center font-semibold mb-8 hover:text-pink-600'>{post.title}
            </h1>
        </NavLink>
        <div className="block text-center items-center justify-center mb-8">
            <div className="flex items-center justify-center mb-4 mr-8">
                <img alt={post.author.name}
                    height='30px'
                    width='30px'
                    className='align-center rounded-full'
                    src={post.author.photo.url} />
                <p className='inline align-middle text-gray-700 ml-2 text-lg'>{post.author.name}</p>
            </div>
            <div className="font-medium text-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 inline mr-2 text-pink-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
                <span>
                    { moment(post.createdAt).format('DD MMM, YYYY') }
                </span>
            </div>
        </div>
        <p className='text-center text-lg text-gray-700 font-normal px-4 mb-8'>{post.excerpt}</p>
    </div>
)
    }

export default Post


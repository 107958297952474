// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, browserLocalPersistence,browserSessionPersistence, setPersistence } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// using account under dntrplyind@gmail.com

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDFbU38AXyrWOkUEE2Fr_s4Qb9j81Sm7aY",
  authDomain: "wowwill-1c360.firebaseapp.com",
  projectId: "wowwill-1c360",
  storageBucket: "wowwill-1c360.appspot.com",
  messagingSenderId: "605266578836",
  appId: "1:605266578836:web:1420b80a783b049d115381",
  measurementId: "G-D98JT33NWQ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
// const analytics = getAnalytics(app);

// auth.setPersistence("local");

// follow https://stackoverflow.com/questions/42878179/how-to-persist-a-firebase-login
(async () => {
  await setPersistence(auth, browserSessionPersistence);
})();
import React from 'react'
import Navigation from '../components/Navigation'
import Footer from '../components/Footer'

const Terms = () => {
  return (
    <div>
    <Navigation/>

    <div className='max-w-[900px] mx-auto'>
    <h1 className='font-bold text-4xl my-8'>Standard Terms and Conditions for Goods and Services</h1>
    
<p>1. Application of Terms and Conditions</p>

<p>1.1 The Supplier shall supply, and the Customer OR Reseller shall purchase the Goods and Services in accordance with the quotation and sales order confirmation which shall be subject to these Terms and Conditions; and</p>

<p>1.2 The Contract shall be to the exclusion of any other terms and conditions subject to which any such quotation is accepted or purported to be accepted, or any such order is made or purported to be made, by the Customer or Reseller.</p>

<p>2. Definitions and Interpretation</p>

<p>In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:</p>

<p>“Business Day”</p>

<p>means any day other than a Saturday, Sunday or bank holiday;</p>

<p>“Commencement Date”</p>

<p>means the commencement date for the Contract as set out in the specified schedule;</p>

<p>“Confidential Information”</p>

<p>means, in relation to either Party, information which is disclosed to that Party by the other Party pursuant to or in connection with this Agreement (whether orally or in writing or any other medium, and whether or not the information is expressly stated to be confidential or marked as such);</p>

<p>“Contract”</p>

<p>means the contract for the purchase and sale of the Goods and supply of the Services under these Terms and Conditions;</p>

<p>“Contract Price”</p>

<p>means the price stated in the Contract payable for the Goods;</p>

<p>“Customer”</p>

<p>means the person who accepts a quotation or offer of the Supplier for the sale of the Goods and supply of the Services, or whose order for the Goods and Services is accepted by the Supplier;</p>

<p>“Delivery Date”</p>

<p>means the date on which the Goods are to be delivered as stipulated in the Customer’s or Resellers order and accepted by the Supplier;</p>

<p>“End User”</p>

<p>means the date on which the Goods are to be delivered as stipulated in the Customer’s or Resellers order and accepted by the Supplier;</p>

<p>“Goods”</p>

<p>means the goods (including any instalment of the goods or any parts for them) which the Supplier is to supply in accordance with these Terms and Conditions;</p>

<p>“Month”</p>

<p>means a calendar month;</p>

<p>“Reseller”</p>

<p>means the organisation that resells the services or products to an end user</p>

<p>“Services”</p>

<p>means the Services to be provided to the Customer or Reseller as set out in the specified schedule; and quotation</p>

<p>“Supplier”</p>

<p>Means wowwill</p>

<p>2.1 Unless the context otherwise requires, each reference in these Terms and Conditions to:</p>

<p>2.1.1 “writing”, and any cognate expression, includes a reference to any communication effected by electronic or facsimile transmission or similar means;</p>

<p>2.1.2 a statute or a provision of a statute is a reference to that statute or provision as amended or re-enacted at the relevant time;</p>

<p>2.1.3 “these Terms and Conditions” is a reference to these Terms and Conditions and any Schedules as amended or supplemented at the relevant time;</p>

<p>2.1.4 a Schedule is a schedule to these Terms and Conditions; and</p>

<p>2.1.5 a Clause or paragraph is a reference to a Clause of these Terms and Conditions (other than the Schedules) or a paragraph of the relevant Schedule.</p>

<p>2.1.6 a “Party” or the “Parties” refer to the parties to these Terms and Conditions.</p>

<p>2.2 The headings used in these Terms and Conditions are for convenience only and shall have no effect upon the interpretation of these Terms and Conditions.</p>

<p>2.3 Words imparting the singular number shall include the plural and vice versa.</p>

<p>2.4 References to any gender shall include the other gender.</p>

<p>3. Basis of Sale and Service</p>

<p>3.1 The Supplier’s employees or agents are not authorised to make any representations concerning the Goods or Services unless confirmed by the Supplier in writing. In entering into the Contract, the Customer or Reseller acknowledges that it does not rely on, and waives any claim for breach of, any such representations which are not so confirmed.</p>

<p>3.2 No variation to these Terms and Conditions shall be binding unless agreed in writing between the authorised representatives of the Customer or Reseller and the Supplier.</p>

<p>3.3 Sales literature, price lists and other documents issued by the Supplier in relation to the Goods and Services are subject to alteration without notice and do not constitute offers to sell the Goods which are capable of acceptance. No contract for the sale of the Goods and Services shall be binding on the Supplier unless the Supplier has issued a quotation which is expressed to be an offer to sell the Goods and Services or has accepted an order placed by the Customer or Reseller by whichever is the earlier of:</p>

<p>3.3.1 the Supplier’s written acceptance;</p>

<p>3.3.2 delivery of the Goods;</p>

<p>3.3.3 provision of the Services; or</p>

<p>3.3.4 the Supplier’s invoice.</p>

<p>3.4 Any typographical, clerical or other accidental errors or omissions in any sales literature, quotation, price list, acceptance of offer, invoice or other document or information issued by the Supplier shall be subject to correction without any liability on the part of the Supplier.</p>

<p>4. The Goods</p>

<p>4.1 No order submitted by the Customer or Reseller shall be deemed to be accepted by the Supplier unless and until confirmed in writing by the Supplier’s authorised representative.</p>

<p>4.2 The specification for the Goods shall be that set out in the Supplier’s sales documentation unless varied expressly in the Customer’s or Resellers order (if such variation(s) is/are accepted by the Supplier). The Goods will only be supplied in the minimum units thereof stated in the Supplier’s price list or in multiples of those units. Orders received for quantities other than these will be adjusted accordingly</p>

<p>4.3 Illustrations, photographs or descriptions whether in catalogues, brochures, price lists or other documents issued by the Supplier are intended as a guide only and shall not be binding on the Supplier.</p>

<p>4.4 The Supplier reserves the right to make any changes in the specification of the Goods which are required to conform with any applicable safety or other statutory or regulatory requirements or, where the Goods are to be supplied to the Customer’s or Resellers specification, which do not materially affect their quality or performance.</p>

<p>4.5 No order which has been accepted by the Supplier may be cancelled by the Customer or Reseller except with the agreement in writing of the Supplier on the terms that the Customer or Reseller shall indemnify the Supplier in full against all loss (including loss of profit), costs (including the cost of all labour and materials used), damages, charges and expenses incurred by the Supplier as a result of such cancellation.</p>

<p>5. The Services</p>

<p>5.1 With effect from the Commencement Date the Supplier shall, in consideration of the price being paid in accordance with Clauses 6 and 7 will provide the Services expressly identified in the quotation or specified schedule.</p>

<p>5.2 The Supplier will use reasonable care and skill to perform the Services identified in the quotation or specified schedule.</p>

<p>5.3 The Supplier shall use its reasonable endeavours to complete its obligations under the Contract, but time will not be of the essence in the performance of such obligations.</p>

<p>6. Price</p>

<p>6.1 The price of the Goods and Services shall be the price listed in the Supplier’s quotation current at the date of acceptance of the Customer’s or Resellers order or such other price as may be agreed in writing by the Supplier and the Customer or Reseller.</p>

<p>6.2 Where the Supplier has quoted a price for the Goods this will be valid for 30 (Thirty) days only or such lesser time as the Supplier may specify.</p>

<p>6.3 The Supplier reserves the right, by giving written notice to the Customer or Reseller at any time before delivery or provision, to increase the price of the Goods and/or Services to reflect any increase in the cost to the Supplier which is due to any factor beyond the control of the Supplier (including, without limitation, any foreign exchange fluctuation, currency regulation, alteration of duties, significant increase in the costs of labour, materials or other costs of manufacture), any change in delivery dates, quantities or specifications for the Goods and services which are requested by the Customer or Reseller, or any delay caused by any instructions of the Customer or Reseller or failure of the Customer or Reseller to give the Supplier adequate information or instructions.</p>

<p>6.4 Except as otherwise stated under the terms of any quotation or specified schedule or in any price list of the Supplier, and unless otherwise agreed in writing between the Customer or Reseller and the Supplier, all prices are inclusive of the Supplier’s charges for packaging and transport.</p>

<p>6.5 The price is exclusive of any applicable value added tax, excise, sales taxes or levies of a similar nature which are imposed or charged by any competent fiscal authority in respect of the Goods and Services, which the Customer or Reseller shall be additionally liable to pay to the Supplier.</p>

<p>7. Payment</p>

<p>7.1 Subject to any special terms agreed in writing between the Customer or Reseller and the Supplier, the Supplier shall invoice the Customer or Reseller for the price of the Goods and Services on or at any time after delivery of the Goods and/or the Provision of the Services (as applicable), unless, in the case of Goods, the Goods are to be collected by the Customer or Reseller or the Customer or Reseller wrongfully fails to take delivery of the Goods, in which event the Supplier shall be entitled to invoice the Customer or reseller for the price at any time after the Supplier has notified the Customer or Reseller that the Goods are ready for collection or (as the case may be) the Supplier has tendered delivery of the Goods.</p>

<p>7.2 The Customer or Reseller shall pay the price of the Goods (less any discount or credit allowed by the Supplier, but without any other deduction, credit or set off) within 30 (Thirty) Business Days of the date of the Supplier’s invoice or otherwise in accordance with such credit terms as may have been agreed in writing between the Customer or Reseller and the Supplier in respect of the Contract. Payment shall be made on the due date notwithstanding that delivery or provision may not have taken place and/or that the property in the Goods has not passed to the Customer or Reseller. The time for the payment of the price shall be of the essence of the Contract. Receipts for payment will be issued only upon request.</p>

<p>7.3 All payments shall be made to the Supplier as indicated on the form of acceptance or invoice issued by the Supplier.</p>

<p>7.4 The Supplier is not obliged to accept orders from any Customer or Reseller or buyer who has not supplied the Supplier with references satisfactory to the Supplier. If at any time the Supplier is not satisfied as to the creditworthiness of the Customer or Reseller it may give notice in writing to the Customer or Reseller that no further credit will be allowed to the Customer or Reseller in which event no further goods or services will be delivered or provided to the Customer or Reseller other than against cash payment and notwithstanding sub-Clause 7.2 of these conditions, all amounts owing by the Customer or Reseller to the Supplier shall be immediately payable in cash.</p>

<p>8. Delivery and Performance</p>

<p>8.1 Delivery of the Goods shall be made by the Supplier delivering the Goods to the place specified in the quotation or specified schedule or, if no place of delivery is so specified, by the Customer or Reseller collecting the Goods at the Supplier’s premises at any time after the Supplier has notified the Customer or Reseller that the Goods are ready for collection.</p>

<p>8.2 The Delivery Date is approximate only and time for delivery shall not be of the essence unless previously agreed by the Supplier in writing. The Goods may be delivered by the Supplier in advance of the Delivery Date upon giving reasonable notice to the Customer or Reseller.</p>

<p>8.3 If the Customer or Reseller fails to take delivery of the Goods or any part of them on the Delivery Date and/or fails to provide any instructions, documents, licences, consents or authorisations required to enable the Goods to be delivered on that date, the Supplier shall be entitled upon giving written notice to the Customer or Reseller to store or arrange for the storage of the Goods and then notwithstanding the provisions of sub-Clause 10.1 risk in the Goods shall pass to the Customer or Reseller, delivery shall be deemed to have taken place and the Customer or Reseller shall pay to the Supplier all costs and expenses including storage and insurance charges arising from such failure.</p>

<p>8.4 With effect from the Commencement Date the Supplier shall, in consideration of the price being paid in accordance with these Terms and Conditions and the quotation or specified schedule provide the Services expressly identified in the quotation or specified schedule.</p>

<p>9. Non-Delivery of Goods and Services</p>

<p>9.1 If the Supplier fails to deliver the Goods or provide the Services or any of them on the Delivery Date (or Commencement Date, as appropriate) other than for reasons outside the Supplier’s reasonable control or the Customer’s or Resellers or its carrier’s fault:</p>

<p>9.1.1 if the Supplier delivers the Goods and/or provides the Services at any time thereafter the Supplier shall have no liability in respect of such late delivery;</p>

<p>10. Risk and Retention of Title</p>

<p>10.1 Risk of damage to or loss of the Goods shall pass to the Customer or Reseller at:</p>

<p>10.1.1 in the case of Goods to be delivered at the Supplier’s premises, the time when the Supplier notifies the Customer or Reseller that the Goods are available for collection;</p>

<p>10.1.2 in the case of Goods to be delivered otherwise than at the Supplier’s premises, the time of delivery or, if the Customer or Reseller wrongfully fails to take delivery of the Goods, the time when the Supplier has tendered delivery of the Goods; or</p>

<p>10.1.3 in the case of Goods being installed by the Supplier, the time that the Supplier notifies the Customer or Reseller that the installation is complete.</p>

<p>10.2 Notwithstanding delivery and the passing of risk in the Goods, or any other provision of these Terms and Conditions, legal and beneficial title to the Goods shall not pass to the Customer or Reseller until the Supplier has received in cash or cleared funds payment in full of the price of the Goods.</p>

<p>10.3 Sub-Clause 10.2 notwithstanding, legal and beneficial title of the Goods shall not pass to the Customer or Reseller until the Supplier has received in cash or cleared funds payment in full of the price of the Goods and any other goods supplied by the Supplier and the Customer or Reseller has repaid all moneys owed to the Supplier, regardless of how such indebtedness arose.</p>

<p>10.4 Until payment has been made to the Supplier in accordance with these Conditions and title in the Goods has passed to the Customer or Reseller, the Customer or Reseller shall be in possession of the Goods as bailee for the Supplier and the Customer or Reseller shall store the Goods separately and in an appropriate environment, shall ensure that they are identifiable as being supplied by the Supplier and shall insure the Goods against all reasonable risks.</p>

<p>10.5 The Customer or Reseller shall not be entitled to pledge or in any way charge by way of security for any indebtedness any of the Goods which remain the property of the Supplier, but if the Customer or Reseller does so all money owing by the Customer or Reseller to the Supplier shall (without prejudice to any other right or remedy of the Supplier) forthwith become due and payable.</p>

<p>10.6 The Supplier reserves the right to repossess any Goods in which the Supplier retains title without notice. The Customer or Reseller irrevocably authorises the Supplier to enter the Customer’s or Resellers premises during normal business hours for the purpose of repossessing the Goods in which the Supplier retains title and inspecting the Goods to ensure compliance with the storage and identification requirements of sub-Clause 10.4.</p>

<p>10.7 The Customer’s or Resellers right to possession of the Goods in which the Supplier maintains legal and beneficial title shall terminate if:</p>

<p>10.7.1 the Customer or Reseller commits or permits any material breach of his obligations under these Terms and Conditions;</p>

<p>10.7.2 the Customer or Reseller enters into a voluntary arrangement under Parts I or VIII of the Insolvency Act 1986, the Insolvent Partnerships Order 1994 (as amended), or any other scheme or arrangement is made with his creditors;</p>

<p>10.7.3 the Customer or Reseller is or becomes the subject of a bankruptcy order or takes advantage of any other statutory provision for the relief of insolvent debtors;</p>

<p>10.7.4 the Customer or Reseller convenes any meeting of its creditors, enters into voluntary or compulsory liquidation, has a receiver, manager, administrator or administrative receiver appointed in respect of its assets or undertaking or any part thereof, any documents are filed with the court for the appointment of an administrator in respect of the Customer or Reseller, notice of intention to appoint an administrator is given by the Customer, Reseller or any of its directors or by a qualifying floating charge-holder (as defined in paragraph 14 of Schedule B1 of the Insolvency Act 1986), a resolution is passed or petition presented to any court for the winding up of the Customer or Reseller or for the granting of an administration order in respect of the Customer or Reseller, or any proceedings are commenced relating to the insolvency or possible insolvency of the Customer or Reseller.</p>

<p>11. Assignment</p>

<p>11.1 The Supplier may assign the Contract or any part of it to any person, firm or company without the prior consent of the Customer or Reseller.</p>

<p>11.2 The Customer or Reseller shall not be entitled to assign the Contract or any part of it without the prior written consent of the Supplier.</p>

<p>12. Defective Goods</p>

<p>12.1 If on delivery any of the Goods are defective in any material respect and either the Customer or Reseller lawfully refuses delivery of the defective Goods or, if they are signed for on delivery as “condition and contents unknown” the Customer or Reseller gives written notice of such defect to the Supplier within 7 (Seven) Business Days of such delivery, the Supplier shall at its option:</p>

<p>12.1.1 replace the defective Goods within 14 (Fourteen) Business Days of receiving the Customer’s or Resellers notice; or</p>

<p>12.1.2 refund to the Customer or Reseller the price for those Goods (or parts thereof, as appropriate) which are defective;</p>

<p>but the Supplier shall have no further liability to the Customer or Reseller in respect thereof and the Customer or Reseller may not reject the Goods if delivery is not refused or notice given by the Customer or Reseller as set out above.</p>

<p>12.2 No Goods may be returned to the Supplier without the prior agreement in writing of the Supplier. Subject thereto any Goods returned which the Supplier is satisfied were supplied subject to defects of quality or condition which would not be apparent on inspection shall either be replaced free of charge or, at the Supplier’s sole discretion the Supplier shall refund or credit to the Customer or Reseller the price of such defective Goods but the Supplier shall have no further liability to the Customer or Reseller.</p>

<p>12.3 The Supplier shall be under no liability in respect of any defect arising from fair wear and tear, or any wilful damage, negligence, subjection to normal conditions, failure to follow the Supplier’s instructions (whether given orally or in writing), misuse or alteration of the Goods without the Supplier’s prior approval, or any other act or omission on the part of the Customer or Reseller, its employees or agents or any third party.</p>

<p>12.4 Goods, other than defective Goods returned under sub-Clauses 12.1 or 12.2, returned by the Customer or Reseller and accepted by the Supplier may be credited to the Customer or Reseller at the Supplier’s sole discretion and without any obligation on the part of the Supplier.</p>

<p>12.5 Subject as expressly provided in these Terms and Conditions, and except where the Goods are sold under a consumer sale, all warranties, conditions or other terms implied by statute or common law are excluded to the fullest extent permitted by law.</p>

<p>12.6 The Customer or Reseller shall be responsible for ensuring that, except to the extent that instructions as to the use or sale of the Goods are contained in the packaging or labelling of the Goods, any use or sale of the Goods by the Customer or Reseller is in compliance with all applicable statutory requirements and that handling and sale of the Goods by the Customer or Reseller is carried out in accordance with directions given by the Supplier or any competent governmental or regulatory authority and the Customer or Reseller will indemnify the Supplier against any liability loss or damage which the Supplier might suffer as a result of the Customer’s or Resellers failure to comply with this condition.</p>

<p>13. Customer’s or Resellers Default</p>

<p>13.1 If the Customer or Reseller fails to make any payment on the due date then, without prejudice to any other right or remedy available to the Supplier, the Supplier shall be entitled to:</p>

<p>13.1.1 cancel the order or suspend any further deliveries or provision of Goods and Services to the Customer or Resellers;</p>

<p>13.1.2 appropriate any payment made by the Customer or Resellers to such of the Goods and/or Services (or the goods and/or services supplied under any other contract between the Customer or Reseller and the Supplier) as the Supplier may think fit (notwithstanding any purported appropriation by the Customer or Reseller); and</p>

<p>13.1.3 charge the Customer or Reseller interest (both before and after any judgement) on the amount unpaid, at the rate of 8.5% per annum above the Bank of England base rate from time to time, until payment in full is made (a part of a month being treated as a full month for the purpose of calculating interest).</p>

<p>13.2 This condition applies if:</p>

<p>13.2.1 the Customer or Reseller fails to perform or observe any of its obligations hereunder or is otherwise in breach of the Contract;</p>

<p>13.2.2 the Customer or Reseller becomes subject to an administration order or enters into a voluntary arrangement under Parts I or VIII of the Insolvency Act 1986 or the Insolvent Partnerships Order 1994 (as amended) or (being an individual or firm) becomes bankrupt or (being a company) goes into liquidation;</p>

<p>13.2.3 an encumbrancer takes possession, or a receiver is appointed, of any of the property or assets of the Customer or Resellers;</p>

<p>13.2.4 the Customer or Reseller ceases, or threatens to cease, to carry on business; or</p>

<p>13.2.5 the Supplier reasonably apprehends that any of the events mentioned above is about to occur in relation to the Customer or Reseller and notifies the Customer or Reseller accordingly.</p>

<p>13.3 If sub-Clause 13.2 applies then, without prejudice to any other right or remedy available to the Supplier, the Supplier shall be entitled to cancel the Contract or suspend any further deliveries under the Contract without any liability to the Customer or Reseller, and if the Goods have been delivered but not paid for the price shall become immediately due and payable notwithstanding any previous agreement or arrangement to the contrary.</p>

<p>14. Liability</p>

<p>14.1 The Supplier will not by reason of any representation, implied warranty, condition or other term, or any duty at common law or under express terms of the Contract (or these Terms and Conditions), be liable for any loss of profit or any indirect, special or consequential loss, damage, costs, expenses or other claims (whether caused by the Supplier’s servants or agents or otherwise) which arise out of or in connection with the supply of the Goods and Services.</p>

<p>14.2 All warranties, conditions and other terms implied by statute or common law (save for the conditions implied by section 12 of the Sale of Goods Act 1979) are, to the fullest extent permitted by law, excluded from the Contract.</p>

<p>14.3 The Customer or Reseller shall indemnify the Supplier against all damages, costs, claims and expenses suffered by arising from loss or damage to any equipment (including that of third parties) caused by the Customer or Reseller, its agents or employees.</p>

<p>14.4 Where the Customer or Reseller consists of two or more persons such expression throughout shall mean and include such two or more persons and each or any of them. All obligations on the part of such a Customer or Reseller shall be joint and several obligations of such persons.</p>

<p>14.5 The Supplier shall not be liable to the Customer or Reseller or be deemed to be in breach of these terms and conditions by reason of any delay in performing, or any failure to perform, any of the Supplier’s obligations if the delay or failure was due to any cause beyond the Supplier’s reasonable control.</p>

<p>14.6 Nothing in these Terms and Conditions excludes or limits the liability of the Supplier:</p>

<p>14.6.1 for death or personal injury caused by the Supplier’s negligence;</p>

<p>14.6.2 for any matter which it would be illegal for the Supplier to exclude or attempt to exclude its liability; or</p>

<p>14.6.3 for fraud or fraudulent misrepresentation.</p>

<p>14.7 Subject to the remaining provisions of this Clause 14:</p>

<p>14.7.1 the Supplier’s total liability in contract, tort (including negligence or breach of statutory duty), misrepresentation, restitution or otherwise, arising in connection with the performance or contemplated performance of the Contract shall be limited to the Contract Price; and</p>

<p>14.7.2 the Supplier shall not be liable to the Customer or Reseller for any pure economic loss, loss of profit, loss of business, depletion of goodwill or otherwise, in each case whether direct, indirect or consequential, or any claims for consequential compensation whatsoever (howsoever caused) which arise out of or in connection with the Contract</p>

<p>15. Confidentiality</p>

<p>15.1 Each Party undertakes that, except as provided by sub-Clause 15.2 or as authorised in writing by the other Party, it shall, at all times during the continuance of the Contract and for 6 months after its termination:</p>

<p>15.1.1 keep confidential all Confidential Information;</p>

<p>15.1.2 not disclose any Confidential Information to any other person;</p>

<p>15.1.3 not use any Confidential Information for any purpose other than as contemplated by and subject to these Terms and Conditions and the Contract;</p>

<p>15.1.4 not make any copies of, record in any way or part with possession of any Confidential Information; and</p>

<p>15.1.5 ensure that none of its directors, officers, employees, agents or advisers does any act which, if done by that Party, would be a breach of the provisions of sub-clauses 15.1.1 to 15.1.4 above.</p>

<p>15.2 Either Party may:</p>

<p>15.2.1 disclose any Confidential Information to:</p>

<p>15.2.1.1 any sub-contractor or supplier of that Party;</p>

<p>15.2.1.2 any governmental or other authority or regulatory body; or</p>

<p>15.2.1.3 any employee or officer of that Party or of any of the aforementioned persons, parties or bodies; to such extent only as is necessary for the purposes contemplated by these Terms and Conditions and the Contract, or as required by law, and in each case subject to that Party first informing the person, party or body in question that the Confidential Information is confidential and (except where the disclosure is to any such body as is mentioned in sub-Clause 15.2.1.2 above or any employee or officer of any such body) obtaining and submitting to the other Party a written undertaking from the person in question, as nearly as practicable in the terms of this Clause 15, to keep the Confidential Information confidential and to use it only for the purposes for which the disclosure is made; and</p>

<p>15.2.2 use any Confidential Information for any purpose, or disclose it to any other person, to the extent only that it is at the date of the Contract, or at any time after that date becomes, public knowledge through no fault of that Party, provided that in doing so that Party does not disclose any part of that Confidential Information which is not public knowledge.</p>

<p>15.3 The provisions of this Clause 15 shall continue in force in accordance with their terms, notwithstanding the termination of the Contract for any reason.</p>

<p>16. Intellectual Property</p>

<p>16.1 Each party shall continue to own the Intellectual Property Rights in all documents, goods and services owned by that party prior to the date of the Contract.</p>

<p>16.2 So far as is required for the completion of the Contract the party owning such Intellectual Property Rights shall grant to the other a royalty free, non-exclusive, non-assignable licence that will terminate on the termination of the Contract.</p>

<p>16.3 The Supplier shall own the Intellectual Property Rights in all documents, Goods and Services created by it during the course of the Contract.</p>

<p>16.4 The Customer or Reseller acknowledges and agrees that where Services comprise or include software and programming, copyright and any other intellectual property rights in the information, software and material therein and in their arrangement and programming, is owned by the Supplier or used under licence from third party copyright owners.</p>

<p>16.5 The Supplier grants the Customer or Reseller a royalty free, non-exclusive, non- assignable licence to use any software for the explicit purposes of operating Goods supplied under this Contract.</p>

<p>16.6 The Customer or Reseller shall not unless otherwise agreed in writing</p>

<p>16.6.1 copy, adapt or reverse compile the whole or any part of the software;</p>

<p>16.6.2 assign, transfer, sell, lease, rent, charge or otherwise deal in the software or use the software on behalf of any third party or make available the same to any third party;</p>

<p>16.6.3 remove or alter any copyright or other proprietary notice in the software;</p>

<p>16.6.4 receive or be entitled to the programming source code or compiled code.</p>

<p>17. Communications</p>

<p>17.1 All notices under these Terms and Conditions and under the Contract shall be in writing and be deemed duly given if signed by, or on behalf of, a duly authorised officer of the Party giving the notice.</p>

<p>17.2 Notices shall be deemed to have been duly given:</p>

<p>17.2.1 when delivered, if delivered by courier or other messenger (including registered mail) during the normal business hours of the recipient; or</p>

<p>17.2.2 when sent, if transmitted by facsimile or e-mail and a successful transmission report or return receipt is generated; or</p>

<p>17.2.3 on the fifth business day following mailing, if mailed by national ordinary mail, postage prepaid; or</p>

<p>17.2.4 on the tenth business day following mailing, if mailed by airmail, postage prepaid.</p>

<p>17.3 All notices under this Agreement shall be addressed to the most recent address, e-mail address, or facsimile number notified to the other Party.</p>

<p>18. Force Majeure</p>

<p>Neither Party shall be liable for any failure or delay in performing their obligations where such failure or delay results from any cause that is beyond the reasonable control of that Party. Such causes include, but are not limited to: power failure, Internet Service Provider failure, industrial action, civil unrest, fire, flood, storms, earthquakes, acts of terrorism, acts of war, governmental action or any other event that is beyond the control of the Party in question.</p>

<p>19. Waiver</p>

<p>The Parties agree that no failure by either Party to enforce the performance of any provision in these Terms and Conditions or under the Contract shall constitute a waiver of the right to subsequently enforce that provision or any other provision. Such failure shall not be deemed to be a waiver of any preceding or subsequent breach and shall not constitute a continuing waiver.</p>

<p>20. Severance</p>

<p>The Parties agree that, in the event that one or more of the provisions of these Terms and Conditions or the Contract are found to be unlawful, invalid or otherwise unenforceable, that / those provisions shall be deemed severed from the remainder of these Terms and Conditions (and, by extension, the Contract). The remainder of these and the Contract shall be valid and enforceable.</p>

<p>21. Third Party Rights</p>

<p>A person who is not a party to the Contract shall have no rights under the Contract pursuant to the Contracts (Rights of Third Parties) Act 1999.</p>

<p>22. Law and Jurisdiction</p>

<p>22.1 These Terms and Conditions and the Contract (including any non-contractual matters and obligations arising therefrom or associated therewith) shall be governed by, and construed in accordance with, the laws of England and Wales.</p>

<p>22.2 Any dispute, controversy, proceedings or claim between the Parties relating to these Terms and Conditions or to the Contract (including any non-contractual matters and obligations arising therefrom or associated therewith) shall fall within the jurisdiction of the courts of England and Wales.</p>

<p>23. Warranties</p>

<p>23.1 the supplier shall pass back to back any manufacturer warranty on to the Reseller, it is then the responsibility of the Reseller to pass the warranty on to the end user.</p>

<p>23.2 Any warranty claims made by the end user must first go to the Reseller who must verify that the warranty period is still valid</p>

<p>23.3 the supplier if required to provide proof of purchase will provide this directly to the manufacturer</p>
</div>

<Footer />
    </div>
    
  )
}

export default Terms
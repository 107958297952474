import moment from 'moment';
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { graphcms, QUERY_COMMENTS } from "../../Graphql/Queries";


const Comments = ({ slug }) => {

  const [comments, setComments] = useState([]);

  console.log("Comments.jsx --> Entered")
  console.log("Comments.jsx --> slug --> ", slug)

  useEffect(() => {

    console.log("Comments.jsx --> useEffect --> before call to QUERY_COMMENTS")
    console.log("Comments.jsx --> useEffect --> slug --> ", slug)

    graphcms.request(QUERY_COMMENTS, { slug })
      .then(res => setComments(res.comments))
  }, [slug])


  return (
    <>
      {console.log("Commnts.jsx --> comments.lenght= ", comments.length)}
      {comments.length ? (
        <div className='shadow-lg rounded-lg p-8 pb-12 mb-8'>
          <h3 className='text-xl font-semibold mb-8 border-b pb-4'>
            {comments.length == 1 ? `${comments.length} Comment` : `${comments.length} Comments`}
          </h3>
          {comments.map((comment) => (
            <div key={comment.createdAt} className='bordr-b border-gray-100 mb-4 pb-4'>
              <p className='mb-4'>
                <span className="font-semibold">{comment.name}</span>
                {` on ${moment(comment.createdAt).format("DD, MMM, YYYY")} `}
                </p>
                <p className="whitespace-pre-line text-gray-600 w-full">{parse(comment.comment)}</p>
            </div>
          ))}

        </div>
       ) : <h3 className="text-xs font-semibold">Be the first to comment above</h3>
      }
    </>
  )
}

export default Comments
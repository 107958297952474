import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from "react-router-dom";
import { graphcms, QUERY_POSTS, QUERY_POSTS_BY_CATEGORY, QUERY_POST_SINGLE } from "../../Graphql/Queries";
import Posts from './Posts';
import PostWidget from './PostWidget';
import Categories from './Categories';


const PostsContainer = () => {
    const [posts, setPosts] = useState([]);
    console.log("PostsContainer.jsx --> Entered");

    console.log("PostsContainer.jsx --> useParams = ", useParams());
    const urlParams = useParams();
    const type = urlParams && urlParams.type;
    const slug = urlParams && urlParams.slug;
    console.log("PostsContainer.jsx --> urlParams.type = ", urlParams && urlParams.type);
    let timeout;


    const getPosts = async() => {
    if (urlParams && urlParams.type == "article") {
        console.log("PostsContainer.jsx --> Retrieveing post with slug ", urlParams.slug);
        graphcms.request(QUERY_POST_SINGLE, {slug})
        .then(res => setPosts(res.posts)) 
    } else if(urlParams && urlParams.slug) { 
        console.log("PostsContainer.jsx --> before graphcms QUERY_POSTS_BY_CATEGORY query")
        graphcms.request(QUERY_POSTS_BY_CATEGORY, {slug})
        .then(res => setPosts(res.posts))
} else {
    console.log("PostsContainer.jsx --> before graphcms QUERY_POSTS query")

    graphcms.request(QUERY_POSTS)
        .then(res => setPosts(res.posts))
}
    
    console.log("PostsContainer.jsx: after graphcms query")
    }

    useEffect(() => {
        clearTimeout(timeout);

        // In development mode, we exceed the graphcms API call limits because useEffect
        // is fired twice. Adding a delay may allow graphcms some time to cache the query
        timeout = setTimeout(() => {
            getPosts();
           // do something 1 sec after a click is done
           // but dont do anything if another click happened before 1sec of the previous 
           // click has expired
        }, 500);

    }, [urlParams])
    return (
        <div
            className='container mx-auto px-10 mb-8'
        >
            {console.log("posts.jsx --> ", posts)}
            <div className="grid lg:grid-cols-12 grid-cols-1 gap-12">
                <div className="lg:col-span-8 col-span-1">
                {posts.length && <Posts posts={posts} /> }
                </div>
                <div className="lg:col-span-4 col-span-1">
                    <div className="relative top-8">
                         <PostWidget />
                          <Categories /> 
                        </div>
                </div>
            </div>
        </div>
    )
}

export default PostsContainer

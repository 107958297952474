import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Home from "./pages/Home";
// import SignupLawyersPage from "./pages/SignupLawyersPage";
// import LawyersDashboard from "./pages/LawyersDashboard";
import UserDashboard from "./pages/UserDashboard";
import { WillContextProvider } from "./context/WillContext";
// import BetaSignupPage from "./pages/BetaSignupPage";
import FAQs from "./pages/FAQs";
import Article1 from "./pages/posts/Article1";
import Feedback from "./components/Feedback";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import AboutUs from "./pages/AboutUs";
import RefundPolicy from "./pages/RefundPolicy";
import NotFound from "./pages/NotFound";
import { ContactUs } from "./pages/Contact";
import Blog from "./pages/Blog";
import Registrations from "./pages/Registrations";
import Navigation from "./components/Navigation";
import PostsByCategory from "./pages/PostsByCategory";
import Article from "./pages/Article";
import Chatbot from "./components/Chatbot";

function App() {

  return (
    <div>
      <HelmetProvider>
        <WillContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/register" element={<Registrations />} />
              <Route path="/contact" element={<ContactUs/>}/>
              <Route path="/refund" element={<RefundPolicy />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="user/dashboard" element={<UserDashboard />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/blog/role-of-a-witness" element={<Article1 />} />
              <Route path="/posts" element={<Blog />} />
              <Route path="/posts/:type/:slug" element={<Blog />} />
              {/* <Route path="/posts/category/:slug" element={<PostsByCategory />} /> */}
              {/* <Route path="/posts/article/:slug" element={<Blog />} /> */}
              {/* <Route path="*" element={<NotFound/>}/> */}

              {/* <Route path="/beta" element={<BetaSignupPage />} /> */}
              {/* <Route path="/Signup-Lawyers" element={<SignupLawyersPage />} /> */}
              {/* <Route path="lawyer/dashboard" element={<LawyersDashboard />} /> */}
              
            </Routes>
            <Chatbot/>
          </BrowserRouter>
        </WillContextProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;

import moment from 'moment';
import React from 'react'
import { NavLink, useParams } from 'react-router-dom';
import PostWidget from './PostWidget';
import Categories from './Categories';
import Post from './Post';
import SinglePost from './SinglePost';

const Posts = ({ posts }) => {
    console.log("In Posts.jsx");
    console.log(posts);
    const slug = useParams();
    console.log('slug: ', slug)
    const type = slug.type

    return (
        <>
            {console.log("posts.jsx --> posts= ", posts)}
            {console.log("posts.jsx -->  posts[0]= ", posts[0])}
            {console.log("posts.jsx --> length= ", posts.length)}
            {type == "article" && (<SinglePost post={posts[0]} />) ||
                (posts.length ?
                    posts?.map(post => <Post post={post} />) : <h1 className='text-xl'>No posts in the "{slug.slug}" category</h1>

                )}

        </>
    )

}

export default Posts

// <div>
// {posts.map((post, index) => (
//     <div key={index}
//         className='bg-gray-100 p-4 mb-2 transition overflow-hidden'>
//         <h1 className='transition duration-300 text-center nb-8 cursor-pointer
//     hover:text-pink-600 text-3xl font-semibold'>
//             <NavLink to={post.url} className="flex w-full items-center px-3 py-2 text-md hover:bg-gray-100">
//                 <div>{post.title}</div>
//             </NavLink>
//         </h1>
//         <p className='transition duration-300 hover:text-pink-600 text-lg text-gray-700 nb-8'>
//             <NavLink to={post.url} className="flex w-full items-center px-3 py-2 text-md hover:bg-gray-100">
//                 {post.excerpt}
//             </NavLink>
//         </p>

//     </div>
// ))
// })
// </div>
import React from "react";


import { AiOutlineHome, AiOutlineStock, AiOutlineBank, AiFillGold } from "react-icons/ai";
import { BsFillShieldLockFill, BsPiggyBank } from "react-icons/bs";
import { HiOutlineCurrencyRupee, HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { BiMap } from "react-icons/bi";
import { GiDoubleNecklace, GiOpenTreasureChest, GiSofa } from "react-icons/gi";
import { IoEllipsisHorizontalCircleOutline } from "react-icons/io5"
import { CiMoneyCheck1 } from "react-icons/ci";
import { TfiCar } from "react-icons/tfi"

export const AssetIcon = ({ icon }) => {

    return (
        <div>
            {icon === "AiOutlineHome" && <AiOutlineHome />}
            {icon === "BsPiggyBank" && <BsPiggyBank />}
            {icon === "CiMoneyCheck1" && <CiMoneyCheck1 />}
            {icon === "BiMap" && <BiMap />}
            {icon === "HiOutlineBuildingOffice" && <HiOutlineBuildingOffice />}
            {icon === "AiOutlineStock" && <AiOutlineStock />}
            {icon === "AiFillGold" && <AiFillGold />}
            {icon === "GiDoubleNecklace" && <GiDoubleNecklace />}
            {icon === "HiOutlineCurrencyRupee" && (<HiOutlineCurrencyRupee />)}
            {icon === "AiOutlineBank" && (<AiOutlineBank />)}
            {icon === "BsFillShieldLockFill" && (<BsFillShieldLockFill />)}
            {icon === "HiOutlineLocationMarker" && (<HiOutlineLocationMarker />)}
            {icon === "TfiCar" && (<TfiCar />)}
            {icon === "GiOpenTreasureChest" && (<GiOpenTreasureChest />)}
            {icon === "GiSofa" && (<GiSofa />)}
            {icon === "IoEllipsisHorizontalCircleOutline" && (<IoEllipsisHorizontalCircleOutline />)}
        </div>);
};

export default AssetIcon;
